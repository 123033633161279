import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import "../App.css";
import axios from "axios";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Table from "./Table";
// import { Button } from "@anchor/react-components";
import { TableContext } from "../context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Icon } from "@anchor/react-components";
import { FcApproval } from "react-icons/fc";
import {
  FaCheckCircle,
  FaExclamation,
  FaExclamationCircle,
} from "react-icons/fa";
import { BsExclamation, BsExclamationLg } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import Table5 from "./Table5";
import CloudTableFormat from "./CloudTableFormat";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { IconContext } from "react-icons";
import Tree from "./Tree";
import Table8 from "./Table8";

export default function Home(props) {
  // const [table1, setTable1] = useState([]);
  const {
    table1,
    setTable1,
    applicationName,
    setapplicationName,
    setDeletedRows,
    showCommit,
    setshowCommit,
  } = useContext(TableContext);

  const [suggestions, setSuggestions] = useState("");
  const {
    graphData,
    setGraphData,
    fetchUserDetails,
    rated,
    retired,
    setRetired,
    setRated,
    applicationId,
    setApplicationId,
    token,
    showCertify,
    setShowCertify,
    disableCertify,
    setDisableCertify,
    showSubscribe,
    setShowSubscribe,
    showUnsubscribe,
    setShowUnsubscribe,
    certificationSuccessful,
    setCertificationSuccessful,
    certificationResult,
    setCertificationResult,
    nextCertify,
    setNextCertify,
    data,
    setData,
    fetchData,
    applicationOwner,
    setApplicationOwner,
    showTooltip,
    setShowTooltip,
    autosearch,
    setautosearch,
    tableRightBoundary,
    fetchOwnership,
    showcreatebutton,
    setshowcreatebutton,
    table5,
    setTable5,
    relationshipCategory,
    relationshipCategorySelected,
    setRelationshipCategorySelected,
    tabIndex,
    setTabIndex,
    fetchWithTimeout,
    fetchAppData,
    appData,
    setAppData,
    selectedSuggestion,
    setSelectedSuggestion,
    isRetired,
    setIsRetired,
    cloudData,
    setCloudData,
    subscriptionSuggestions,
    setSubscriptionSuggestions,
    table8,
    setTable8,
    serverData,
    setServerData,
    fetchServerData,
    inputserverName,
    setInputserverName,
    applicationManagerDisplay,
    setApplicationManagerDisplay,
    suggestionSelected,
    setSuggestionSelected,
    appStatus,
    setAppStatus,
    retiredApps,
    setretiredApps,
    showOwner,
    setShowOwner,
  } = useContext(TableContext);
  const [showTable, setshowTable] = useState(false);
  const inputRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [width, setWidth] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [displaySuggestion, setDisplaySuggestion] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [uniqueValues, setuniqueValues] = useState([]);
  const [serverName, setserverName] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [applicationinputValue, setapplicationInputValue] = useState("");
  let debounceTimer;

  const relationshipType = [
    { value: "", label: "Select an option", isDisabled: true },
    {
      value: "upstream",
      label: `upstream - Data flows from input app to ${applicationName}`,
    },
    {
      value: "downstream",
      label: `downstream - Data flows from ${applicationName} to input app`,
    },
  ];
  const [relationshipTypeSelected, setRelationshipType] = useState(
    relationshipCategory[0]
  );

  const [selectedCreateSuggestion, setSelectedCreateSuggestion] =
    useState(false);

  const [serverOptions, setServerOptions] = useState([]);
  const [selectedServerOption, setSelectedServerOption] = useState([]);
  const [applicationNameSelected, setapplicationNameSelected] = useState([]);

  const handleMultiSelectServerChange = (selected) => {
    setSelectedServerOption(selected);
    console.log(`Option selected:`, selected);
  };

  /**
   * Function to show and hide the create form
   */
  const handleSubscriptionNameChange = (event) => {
    setSubscriptionName(event.target.value);
  };

  const showCreate = () => {
    setapplicationInputValue("");
    var x = document.getElementById("createDiv");
    x.style.display = "flex";
  };
  const hideCreate = (e) => {
    // e.preventDefault();
    var x = document.getElementById("createDiv");
    x.style.display = "none";
    setserverName("");
    setapplicationInputValue("");
  };
  /**
   * Function to handle the application name change
   */
  const handleapplicationNameChange = (event) => {
    setSuggestionSelected(false);
    if (relationshipCategorySelected.label == "Server to App") {
      setSelectedSuggestion(true);
      setInputserverName(event.target.value);
    } else {
      setapplicationName(event.target.value);
    }
  };
  useEffect(() => {
    if (!suggestionSelected) {
      const getApplicationSuggestion = setTimeout(async () => {
        const searchBox = document.getElementById("application-name");
        const suggestionBox = document.getElementById("suggestion-box");
        const searchTerm = searchBox.value;
        // Make an AJAX request to fetchWithTimeout application names from the server
        if (searchTerm.length >= 3) {
          suggestionBox.innerHTML = "Loading...";
          suggestionBox.style.display = "block";
          searchBox.style.borderBottomLeftRadius = "0%";
          searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
          suggestionBox.style.padding = "10px";
          const url = /^[Aa]\d{2,}$/.test(searchTerm)
            ? `https://prod-cmdbbackend.azurewebsites.net/getappidlist?applicationID=${encodeURIComponent(
                searchTerm
              )}`
            : `https://prod-cmdbbackend.azurewebsites.net/?applicationName=${encodeURIComponent(
                searchTerm
              )}`;
          fetchWithTimeout(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data != "") {
                // Clear previous suggestions
                suggestionBox.innerHTML = "";
                // Display a maximum of 20 suggestions
                const maxSuggestions = 20;
                for (let i = 0; i < data.length && i < maxSuggestions; i++) {
                  const suggestionItem = document.createElement("div");
                  suggestionItem.classList.add("suggestion-item");
                  suggestionItem.textContent = data[i];
                  suggestionBox.style.padding = "0px";
                  suggestionItem.addEventListener("click", function () {
                    setapplicationName(data[i]);
                    searchBox.style.borderBottomLeftRadius = "5px";
                    searchBox.style.borderBottomRightRadius = "5px";
                    suggestionBox.style.display = "none"; // Hide the suggestion box on click
                    setSelectedSuggestion(true);
                    setSuggestionSelected(true);
                  });
                  suggestionBox.appendChild(suggestionItem);
                }
                if (data.length > 0 && searchTerm.length >= 3) {
                  suggestionBox.style.display = "block";
                  searchBox.style.borderBottomLeftRadius = "0%";
                  searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
                  setSelectedSuggestion(false);
                } else {
                  suggestionBox.style.display = "none";
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
                }
              } else {
                setSelectedSuggestion(true);
                suggestionBox.innerHTML = "No results found";
                suggestionBox.style.display = "block";
                suggestionBox.style.padding = "10px";
                searchBox.style.borderBottomLeftRadius = "0%";
                searchBox.style.borderBottomRightRadius = "0%"; //
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          suggestionBox.style.display = "none";
          searchBox.style.borderBottomLeftRadius = "5px";
          searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
        }
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [applicationName]);

  /**
   * Function to handle the server name change
   */
  const handleserverNameChange = (event) => {
    setserverName(event.target.value);
  };
  // Handler function to update input value and trigger debounced API call
  const handleinputApplicationNameChange = (event) => {
    const value = event.target.value;
    setapplicationInputValue(value);
    setSuggestionSelected(false); // Log the API call after the debounce delay

    // clearTimeout(debounceTimer); // Clear the existing debounce timer
    // debounceTimer = setTimeout(() => {
    //   // Your API call logic here
    //   console.log("API called with input:", value); // Log the API call after the debounce delay
    // }, 5000);
    // debouncedAPICall(value);
  };

  useEffect(() => {
    if (!suggestionSelected) {
      const getApplicationSuggestion = setTimeout(async () => {
        const searchBox = document.getElementById("applicationInputName");
        const suggestionBox = document.getElementById("suggestion-container2");
        setSelectedCreateSuggestion(false);
        console.log(applicationinputValue);
        if (applicationinputValue.length >= 3) {
          const category_select =
            relationshipCategorySelected.value == "app_to_app" ? "create" : "";
          await fetchWithTimeout(
            `https://prod-cmdbbackend.azurewebsites.net/getappsforcertify?applicationName=${encodeURIComponent(
              applicationinputValue
            )}&category=${category_select}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              console.log("data", data);
              // Clear previous suggestions
              suggestionBox.innerHTML = "";
              // Display a maximum of 20 suggestions
              const maxSuggestions = 20;
              for (let i = 0; i < data.length && i < maxSuggestions; i++) {
                const suggestionItem = document.createElement("div");
                suggestionItem.classList.add("suggestion-item");
                suggestionItem.textContent = data[i];
                suggestionItem.addEventListener("click", async function () {
                  setapplicationInputValue(data[i]);
                  setSuggestionSelected(true);
                  // await setcertifyapplicationName(data[i]);
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchBox.style.borderBottomRightRadius = "5px";
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                  // await isciadmin();
                  setSelectedCreateSuggestion(true);
                });
                suggestionBox.appendChild(suggestionItem);
              }
              if (data.length > 0) {
                suggestionBox.style.display = "block";
                searchBox.style.borderBottomLeftRadius = "0%";
                searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
              } else {
                suggestionBox.style.display = "none";
                searchBox.style.borderBottomLeftRadius = "5px";
                searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
        if (applicationinputValue.length == 0) {
          console.log("No data");
          suggestionBox.innerHTML = "";
          suggestionBox.style.display = "none";
          searchBox.style.borderBottomLeftRadius = "5px";
          searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
        }

        console.log("API called with input:", applicationinputValue);
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [applicationinputValue]);

  const handleShowCertify = async (toastMessage) => {
    try {
      // console.log("Inside try block");
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/showcertify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
            logged_in_user: graphData?.displayName,
            relationship_category: relationshipCategorySelected.value,
          }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        if (/^[Aa]\d{2,}$/.test(applicationName)) {
          setCertificationSuccessful(false);
          const userCheckResult = result.user_check;
          console.log(userCheckResult);
          const pendingRequestResult = result.pending_request;
          console.log(pendingRequestResult);
          const certificationResult1 = result.certification;
          console.log(certificationResult1);

          if (
            certificationResult1 == "Not in Production" ||
            userCheckResult === "invalid" ||
            pendingRequestResult === "invalid" ||
            certificationResult1 === "invalid" ||
            toastMessage === "App is retired" ||
            certificationResult1 == "Invalid relationship category" ||
            certificationResult1 == "retired"
          ) {
            setShowCertify(false);
            if (certificationResult1 == "Invalid relationship category") {
              toast.error("Please select a relationship category", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else {
            if (userCheckResult === "Verified") {
              setShowTooltip("");
              if (
                pendingRequestResult.includes(
                  "Please clear the pending requests"
                )
              ) {
                setShowCertify(true);
                setDisableCertify(true);
                setShowTooltip(pendingRequestResult);
              } else if (pendingRequestResult.includes("No pending request")) {
                setShowCertify(true);

                if (
                  certificationResult1.tooltip1.includes(
                    "All the applications you own are retired"
                  ) ||
                  certificationResult1.tooltip1.includes(
                    "You have already certified all the applications you own."
                  )
                ) {
                  setDisableCertify(true);
                  setShowTooltip(certificationResult1.tooltip1);
                }
                if (
                  certificationResult1.tooltip1.includes(
                    "You can certify the following applications"
                  )
                ) {
                  setDisableCertify(false);
                  setShowTooltip(certificationResult1.tooltip1);
                }
              }
            } else {
              setShowCertify(false);
            }
          }
        } else {
          // Access the properties from the result object
          const userCheckResult = result.user_check;
          console.log(userCheckResult);
          const pendingRequestResult = result.pending_request;
          console.log(pendingRequestResult);
          const certificationResult1 = result.certification;
          console.log(certificationResult1);
          // console.log(certificationResult1.tooltip2)

          // console.log('User Check Result:', userCheckResult);
          // console.log('Pending Request Result:', pendingRequestResult);
          if (
            certificationResult1 == "Not in Production" ||
            userCheckResult === "invalid" ||
            pendingRequestResult === "invalid" ||
            certificationResult1 === "invalid" ||
            toastMessage === "App is retired" ||
            certificationResult1 == "Invalid relationship category" ||
            certificationResult1 == "retired"
          ) {
            setShowCertify(false);
            if (certificationResult1 == "Invalid relationship category") {
              toast.error("Please select a relationship category", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }

            if (certificationResult1 == "Not in Production") {
              setCertificationSuccessful(false);
            }
          } else {
            setCertificationSuccessful(certificationResult1 !== "Certify");
            setCertificationResult(certificationResult1.tooltip1);

            if (userCheckResult === "Verified") {
              setShowCertify(true);
              setShowTooltip(
                "As Application Manager, you can review the application to server relationships and validate by clicking the Certify button at every 180 days"
              );

              if (pendingRequestResult === "Pending request") {
                setDisableCertify(true);
                setShowTooltip(
                  "Please clear the pending requests to certify the application"
                );
              } else if (certificationResult1 !== "Certify") {
                setDisableCertify(true);
                setShowTooltip(certificationResult1?.tooltip2);
                if (
                  certificationResult1?.tooltip2?.includes(
                    "Certification expire"
                  )
                ) {
                  setDisableCertify(false);
                }
              } else {
                setDisableCertify(false);
              }
            } else {
              if (
                certificationResult1?.tooltip2?.includes("Certification expire")
              ) {
                setShowTooltip(certificationResult1.tooltip2);
              }
              setShowCertify(false);
            }
          }
        }
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Certify Error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleShowSubscribe = async (toastMessage) => {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/checkSubscriptionStatus",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            email: graphData?.mail,
            relationship_category: relationshipCategorySelected.value,
          }),
        }
      );
      if (response.ok) {
        const subscriptionResult = await response.json();
        console.log(subscriptionResult);
        const certificationResult1 = subscriptionResult.certification;
        const subscriptionStatus = subscriptionResult.subscription_status;

        if (
          certificationResult1 == "Not in Production" ||
          certificationResult1 == "retired"
        ) {
          setShowSubscribe(false);
          setShowUnsubscribe(false);
        } else {
          if (subscriptionStatus == "Subscribed") {
            setShowSubscribe(false);
            setShowUnsubscribe(true);
          } else if (subscriptionStatus == "Not Subscribed") {
            setShowSubscribe(true);
            setShowUnsubscribe(false);
          } else {
            console.error("Failed to fetch subscription status");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
      toast.error("Failed to fetch subscription status", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const fetchCloudData = async () => {
    setSubscriptionSuggestions([]);
    setCloudData([]);
    setShowCertify(false);
    setCertificationResult("");
    setCertificationSuccessful(false);
    setIsRetired(false);
    try {
      const response = await fetch(
        `https://prod-cmdbbackend.azurewebsites.net/getclouddata?applicationName=${encodeURIComponent(
          applicationName
        )}&username=${encodeURIComponent(graphData?.displayName)}&graph_access_token=${token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      props.setProgress(50);
      const data = await response.json();

      if (data?.status == "multiple_matches") {
        toast.error(
          "Multiple matches found for the application name. Please select the correct subscription name from the suggestions",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        // setCloudData(data)
        setCloudData(data)
        console.log(cloudData)
        props.setProgress(100);
        setSubscriptionSuggestions(data?.recommendations);
        if(data?.certified)
        {
          if (data?.certificationDetails.includes("Certified")) {
            setCertificationSuccessful(true);
            setCertificationResult(data?.certificationDetails);
          }
        }
        
        if (data?.isciowner === true) {
          setshowcreatebutton(true);
          console.log("isciowner true");
        } else if (data?.isciowner === false) {
          console.log("isciowner false");
          setshowcreatebutton(false);
        }
        // return;
      } else {
        if (
          data?.resourceGroups.length === 0 &&
          data?.certified_data.length === 0
        ) {
          toast.error("No data found for the application", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setProgress(100);
          if (data?.isciowner === true) {
            setshowcreatebutton(true);
            console.log("isciowner true");
          } else if (data?.isciowner === false) {
            console.log("isciowner false");
            setshowcreatebutton(false);
          }
          return;
        }
        if (data?.certificationDetails.includes("Certified")) {
          setCertificationSuccessful(true);
          setCertificationResult(data?.certificationDetails);
        }
        setCloudData(data);
        props.setProgress(100);
        if (data?.isciowner === true) {
          setshowcreatebutton(true);
          console.log("isciowner true");
        } else if (data?.isciowner === false) {
          console.log("isciowner false");
          setshowcreatebutton(false);
        }
        // setData(await response.json());
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      if (data.isciowner === "true") {
        setshowcreatebutton(true);
      } else if (data.isciowner === "false") {
        setshowcreatebutton(false);
      }
      toast.error("Failed to fetch data", {
        position: "top-center", // Add your own message: "Failed to fetch data"
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      props.setProgress(100);
    }
  };

  /**
   * Function to handle the search submit
   */
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setCloudData([]);
    setShowSubscribe(false);
    setShowUnsubscribe(false);

    if (selectedSuggestion) {
      props.setProgress(10);
      setDisplaySuggestion(false);
      const suggestionBox = document.getElementById("suggestion-box");
      suggestionBox.style.display = "none";
      const searchBox = document.getElementById("application-name");
      searchBox.style.borderBottomLeftRadius = "5px";
      searchBox.style.borderBottomRightRadius = "5px";
      let toastMessage = "";
      if (relationshipCategorySelected.value == "") {
        toast.error("Please select a relationship category", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else {
        if (
          applicationName.length === 0 &&
          relationshipCategorySelected.value != "server_to_app"
        ) {
          toast.error("Application Name Cannot Be Empty", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setProgress(100);
        } else {
          if (relationshipCategorySelected.value == "app_to_server") {
            setTable1([]);
            setData([]);
            if (/^[Aa]\d{2,}$/.test(applicationName)) {
              console.log(applicationName + "Alphanumeric");
              setapplicationNameSelected([]);
              setserverName("");
              setAppStatus();
              setretiredApps();
              try {
                // const application =applicationName.split(' ').join('+');
                await fetch(
                  `https://prod-cmdbbackend.azurewebsites.net/getappiddata?applicationID=${encodeURIComponent(
                    applicationName
                  )}&graph_access_token=${token}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json(), props.setProgress(10))
                  .then((response) => {
                    setApplicationId("");
                    setApplicationManagerDisplay(false);
                    const data = response.data;
                    const filteredStatus = Object.keys(response.status)
                      .filter((key) => response.status[key] !== "retired")
                      .reduce((acc, key) => {
                        acc[key] = response.status[key];
                        return acc;
                      }, {});
                    const retiredKeys = Object.keys(response.status).filter(
                      (key) => response.status[key] === "retired"
                    );

                    console.log("Retired Keys:", retiredKeys);
                    if (retiredKeys.length > 0) {
                      setretiredApps(retiredKeys);
                      const message =
                        retiredKeys.length === 1
                          ? `${retiredKeys[0]} is retired. Showing info for this in read-only mode.`
                          : `${retiredKeys.join(
                              ", "
                            )} are retired. Showing info for these in read-only mode.`;

                      toast.warning(message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }

                    setAppStatus(filteredStatus);
                    console.log("Environment", Object.keys(response.status));
                    const appStatus = response?.status;
                    if (data.length === 0 || data.includes("Production")) {
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      toastMessage =
                        data.length === 0
                          ? "No Relationship Exist"
                          : "App is retired";
                      if (data.includes("Production")) {
                        toastMessage = data;
                        setshowcreatebutton(false);
                      }
                      if (toastMessage === "App is retired") {
                        setshowcreatebutton(false);
                      }
                      if (
                        toastMessage === "No Relationship Exist" &&
                        appStatus !== "retired"
                      ) {
                        setshowcreatebutton(true);
                      }
                      toast.warning(toastMessage, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      setshowTable(false);
                      setTable1([]);
                      setApplicationId("");
                    } else {
                      setshowcreatebutton(true);
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      setTable1(data);
                      setshowTable(true);
                      setApplicationId(applicationName);
                    }
                    //console.log(data)
                  });
                props.setProgress(70);
                //   var temp =await [...new Set(table1.map(row => row[2]))];
                //  await setuniqueValues(temp)
                //   console.log(uniqueValues)
                props.setProgress(100);
                // await getID(applicationName);
                await fetchData();
                await showRate();
                await setTabIndex(0);
                await handleShowCertify(toastMessage);
                await handleShowSubscribe(toastMessage);

                // sessionStorage.setItem("table",JSON.stringify(table1))
                // console.log(table1[1].indexOf(applicationName))
              } catch (error) {
                if (error.message === "Timeout") {
                  toast.error("Request Timed Out", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  console.log(error);
                  setshowcreatebutton(false);
                  toast.error(
                    `Record not found in ${relationshipCategorySelected.label}. App names are case sensitive, please select from below suggestions.`,
                    {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }
              }
            } else {
              try {
                // const application =applicationName.split(' ').join('+');
                await fetchWithTimeout(
                  `https://prod-cmdbbackend.azurewebsites.net/getdata?applicationName=${encodeURIComponent(
                    applicationName
                  )}&graph_access_token=${token}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json(), props.setProgress(10))
                  .then((response) => {
                    setApplicationId("");
                    setApplicationManagerDisplay(true);
                    const data = response.data;
                    const appStatus = response.status;
                    const ownerStatus = response.owner;
                    setShowOwner(ownerStatus);
                    if (ownerStatus == false) {
                      toast.warning(
                        "Restricted: Application has not CI owner assigned",
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    }
                    if (data.length === 0 || data.includes("Production")) {
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      toastMessage =
                        data.length === 0
                          ? "No Relationship Exist"
                          : "App is retired";
                      if (data.includes("Production")) {
                        toastMessage = data;
                        setshowcreatebutton(false);
                      }
                      if (toastMessage === "App is retired") {
                        setshowcreatebutton(false);
                      }
                      if (
                        toastMessage === "No Relationship Exist" &&
                        appStatus !== "retired"
                      ) {
                        setshowcreatebutton(true);
                      }
                      toast.warning(toastMessage, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      setshowTable(false);
                      setTable1([]);
                    } else {
                      setshowcreatebutton(true);
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      setTable1(data);
                      setshowTable(true);
                    }
                    //console.log(data)
                  });
                props.setProgress(70);
                //   var temp =await [...new Set(table1.map(row => row[2]))];
                //  await setuniqueValues(temp)
                //   console.log(uniqueValues)
                props.setProgress(100);
                await getID(applicationName);
                await fetchData();
                await showRate();
                await setTabIndex(0);

                // sessionStorage.setItem("table",JSON.stringify(table1))
                // console.log(table1[1].indexOf(applicationName))
              } catch (error) {
                if (error.message === "Timeout") {
                  toast.error("Request Timed Out", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  console.log(error);
                  setshowcreatebutton(false);
                  toast.error(
                    `Record not found in ${relationshipCategorySelected.label}. App names are case sensitive, please select from below suggestions.`,
                    {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }
                try {
                  const response = await fetchWithTimeout(
                    "https://prod-cmdbbackend.azurewebsites.net/relevantSuggestion",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      body: JSON.stringify({
                        applicationName: applicationName,
                      }),
                    }
                  );
                  if (response.ok) {
                    const result = await response.json();
                    const relevant_application =
                      result.relevant_application_names;
                    setSuggestionList(relevant_application);
                    setDisplaySuggestion(true);
                  } else {
                    console.error("Failed to fetch data");
                  }
                } catch (error) {
                  console.error("An error occurred:", error);
                  toast.error("Failed to fetch Suggestions Badge", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }
              // console.log("After return");
              props.setProgress(100);
              // console.log(token);

              await handleShowCertify(toastMessage);
              await handleShowSubscribe(toastMessage);
            }
          }
          if (relationshipCategorySelected.value == "app_to_app") {
            if (/^[Aa]\d{2,}$/.test(applicationName)) {
              toast.error(
                "Search by App ID is only available for App to Server",
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );

              props.setProgress(100);
            } else {
              setTable5([]);
              setAppData([]);
              try {
                await fetch(
                  `https://prod-cmdbbackend.azurewebsites.net/getappdata?applicationName=${encodeURIComponent(
                    applicationName
                  )}&graph_access_token=${token}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json(), props.setProgress(10))
                  .then((response) => {
                    const data = response.data;
                    const appStatus = response.status;
                    const ownerStatus = response.owner;
                    setShowOwner(ownerStatus);
                    if (ownerStatus == false) {
                      toast.warning(
                        "Restricted: Application has not CI owner assigned",
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    }
                    console.log("Data", data);
                    if (data.length === 0 || data.includes("Production")) {
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      toastMessage =
                        data.length === 0
                          ? "No Relationship Exist"
                          : "App is retired";
                      if (data.includes("Production")) {
                        toastMessage = data;
                        setshowcreatebutton(false);
                        setTable5([]);
                      }
                      if (toastMessage === "App is retired") {
                        setshowcreatebutton(false);
                      }
                      if (toastMessage === "No Relationship Exist") {
                        setshowcreatebutton(true);
                      }
                      toast.warning(toastMessage, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      setTable5([]);
                    } else {
                      setshowcreatebutton(true);
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      setTable5(data);
                      console.log("Test Table5", table5);
                    }
                    //console.log(data)
                  });
                props.setProgress(70);
                //   var temp =await [...new Set(table1.map(row => row[2]))];
                //  await setuniqueValues(temp)
                //   console.log(uniqueValues)
                props.setProgress(100);
                await getID(applicationName);
                await fetchAppData();
                await showRate();
              } catch (error) {
                console.log(error);
                toast.error(
                  `Record not found in ${relationshipCategorySelected.label}. App names are case sensitive, please select from below suggestions.`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                try {
                  const response = await fetchWithTimeout(
                    "https://prod-cmdbbackend.azurewebsites.net/relevantSuggestion",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      body: JSON.stringify({
                        applicationName: applicationName,
                      }),
                    }
                  );
                  if (response.ok) {
                    const result = await response.json();
                    const relevant_application =
                      result.relevant_application_names;
                    setSuggestionList(relevant_application);
                    setDisplaySuggestion(true);
                  } else {
                    console.error("Failed to fetch data");
                  }
                } catch (error) {
                  console.error("An error occurred:", error);
                  toast.error("Failed to fetch Suggestions Badge", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }
              props.setProgress(100);

              await handleShowCertify(toastMessage);
              await handleShowSubscribe(toastMessage);
            }
          }
          if (relationshipCategorySelected.value == "app_to_cloud") {
            if (/^[Aa]\d{2,}$/.test(applicationName)) {
              toast.error(
                "Search by App ID is only available for App to Server",
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );

              props.setProgress(100);
            } else {
              setShowTooltip('');
              await setTabIndex(0);
              await toast.promise(fetchCloudData(), {
                pending: "Fetching Data",
              });
              await handleShowSubscribe(toastMessage);
            }
          }
          if (relationshipCategorySelected.value == "server_to_app") {
            await setTable8([]);
            await setServerData([]);
            await setSelectedServerOption([]);
            await setTabIndex(0);
            await setInputserverName(
              inputserverName
                .replace(/\s+/g, "") // Remove all whitespace characters (including tab spaces)
            );
            await setShowCertify(false);
            await setShowSubscribe(false);
            await setShowUnsubscribe(false);
            await setTabIndex(0);
            try {
              // const application =applicationName.split(' ').join('+');
              const response = await fetchWithTimeout(
                "https://prod-cmdbbackend.azurewebsites.net/showservertoapp",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({
                    Servername: inputserverName,
                  }),
                }
              );
              if (inputserverName.trim() == "") {
                toast.error("Server Name Cannot Be Empty", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                props.setProgress(100);
                setshowCommit(false);
              } else {
                if (response.ok) {
                  const result = await response.json();
                  console.log(result);
                  props.setProgress(70);
                  const applicationNameList = inputserverName.split(",");
                  const uniqueApplicationNameList = Array.from(
                    new Set(applicationNameList)
                  );
                  if (result.length === 0) {
                    setshowcreatebutton(false);
                    if (uniqueApplicationNameList.length < 2) {
                      toast.warning(inputserverName + " is invalid server", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else {
                      toast.warning(inputserverName + " are invalid servers", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }
                    // setshowcreatebutton(true);
                    setTable8([]);
                  } else {
                    setTable8(result);
                    setshowcreatebutton(true);
                  }
                } else {
                  console.error("Failed to fetch data");
                }

                props.setProgress(100);
                await showRate();
                await setTabIndex(0);
                await fetchServerData();
              }

              // sessionStorage.setItem("table",JSON.stringify(table1))
              // console.log(table1[1].indexOf(applicationName))
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                console.log(error);
                toast.error(`Failed to fetch Server to App data`, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            // console.log("After return");
            props.setProgress(100);

            // console.log(token)
          }
        }
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (
      relationshipCategorySelected.label == "Server to App" &&
      inputserverName.trim() != "" &&
      table8.length > 0
    ) {
      const applicationNameList = inputserverName
        .split(",")
        .map((name) => name.trim().replace(/\s+/g, ""))
        .filter((name) => name !== "");
      const uniqueApplicationNameList = Array.from(
        new Set(applicationNameList)
      );

      let validServer = "";
      let invalidServer = "";
      const newServerOptions = [];
      for (let i = 0; i < uniqueApplicationNameList.length; i++) {
        if (indexOf2d(table8, uniqueApplicationNameList[i])) {
          if (validServer == "") {
            validServer += uniqueApplicationNameList[i];
          } else {
            validServer += "," + uniqueApplicationNameList[i];
          }
          newServerOptions.push({
            value: uniqueApplicationNameList[i],
            label: uniqueApplicationNameList[i],
          });
        } else {
          if (invalidServer == "") {
            invalidServer += uniqueApplicationNameList[i];
          } else {
            invalidServer += "," + uniqueApplicationNameList[i];
          }
        }
      }
      console.log("applicationNameList", applicationNameList);
      console.log("uniqueApplicationNameList", uniqueApplicationNameList);
      console.log("ServerOptions", newServerOptions);
      setServerOptions(newServerOptions);
      if (invalidServer != "") {
        if (invalidServer.includes(",")) {
          toast.warning(invalidServer + " are invalid servers", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(invalidServer + " is invalid servers", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
      setInputserverName(validServer);
    }
  }, [table8]);

  /**
   * Handles the submit event when creating a new entry.
   * @param {Event} e - The submit event object.
   * @returns {Promise<void>} - A promise that resolves when the submit event is handled.
   */
  const handleCreateSubmit = async (e) => {
    // setshowCommit(true)
    props.setProgress(50);
    e.preventDefault();
    console.log("Server", serverName);
    // const stringWithoutSpecialChars = serverName.replace(/[^a-zA-Z0-9,]/g, '');
    // console.log("Server without special characters", stringWithoutSpecialChars);
    // const stringWithoutSpaces = stringWithoutSpecialChars.replace(/\s/g, '');
    // console.log("Server withot space",stringWithoutSpaces)
    // const ServerListWithoutSpaces = stringWithoutSpaces.split(',');
    // const ServerList = ServerListWithoutSpaces.filter(item => item !== '');
    const ServerList = serverName
      .replace(/[^a-zA-Z0-9,]/g, "")
      .split(",")
      .filter((item) => item.trim() !== "");
    console.log("Server List", ServerList);
    let sucess_entry = 0;
    if (ServerList.length > 0) {
      if (/^[Aa]\d{2,}$/.test(applicationName)) {
        for (let i = 0; i < Math.min(ServerList.length, 10); i++) {
          if (
            indexOf2d_appID(
              table1,
              ServerList[i],
              applicationNameSelected.value
            )
          ) {
            console.log("Relationship exists");
            toast.error(ServerList[i] + " Relationship Exists", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            props.setProgress(100);
          } else {
            console.log("else part");
            props.setProgress(50);
            // const application =applicationName.split(' ').join('+');
            try {
              const response = await fetchWithTimeout(
                `https://prod-cmdbbackend.azurewebsites.net/create?applicationName=${encodeURIComponent(
                  applicationNameSelected.value
                )}&serverName=${ServerList[i]}&requestedby=${
                  graphData.displayName
                }&graph_access_token=${token}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              props.setProgress(70);
              const data = await response.json();
              props.setProgress(100);
              console.log(data.message);
              if (data.message == "Data processed successfully") {
                toast.success(ServerList[i] + " processed successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                sucess_entry += 1;
              } else if (data.message.includes("Request for relation")) {
                console.log(ServerList[i] + " Relationship exists");
                toast.error(data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                props.setProgress(100);
              } else if (data?.message.includes("Exception: No Owner")) {
                toast.error(
                  "Restricted: Application has not CI owner assigned",
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                props.setProgress(100);
              } else {
                toast.warning(ServerList[i] + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              console.log("Sucessfull Entry", i, sucess_entry);
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Server Error", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            var temp = await [...new Set(table1.map((row) => row[2]))];
            await setuniqueValues(temp);
            console.log(uniqueValues);
          }
          if (i === ServerList.length - 1 && sucess_entry > 0) {
            toast.info("You can confirm your changes", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setshowCommit(true);
          }
        }
      } else {
        for (let i = 0; i < Math.min(ServerList.length, 10); i++) {
          if (indexOf2d(table1, ServerList[i])) {
            console.log("Relationship exists");
            toast.error(ServerList[i] + " Relationship Exists", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            props.setProgress(100);
          } else {
            console.log("else part");
            props.setProgress(50);
            // const application =applicationName.split(' ').join('+');
            try {
              const response = await fetchWithTimeout(
                `https://prod-cmdbbackend.azurewebsites.net/create?applicationName=${encodeURIComponent(
                  applicationName
                )}&serverName=${ServerList[i]}&requestedby=${
                  graphData.displayName
                }&graph_access_token=${token}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              props.setProgress(70);
              const data = await response.json();
              props.setProgress(100);
              console.log(data.message);
              if (data.message == "Data processed successfully") {
                toast.success(ServerList[i] + " processed successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                sucess_entry += 1;
              } else if (data.message.includes("Request for relation")) {
                console.log(ServerList[i] + " Relationship exists");
                toast.error(data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                props.setProgress(100);
              } else if (data?.message.includes("Exception: No Owner")) {
                toast.error(
                  "Restricted: Application has not CI owner assigned",
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                props.setProgress(100);
              } else {
                toast.warning(ServerList[i] + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              console.log("Sucessfull Entry", i, sucess_entry);
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Server Error", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            //   var temp =await [...new Set(table1.map(row => row[2]))];
            //  await setuniqueValues(temp)
            //   console.log(uniqueValues)
          }
          if (i === ServerList.length - 1 && sucess_entry > 0) {
            toast.info("You can confirm your changes", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setshowCommit(true);
          }
        }
      }
    } else if (ServerList.length == 0) {
      toast.error("Server Name Cannot Be Empty", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      props.setProgress(100);
    }
    setserverName("");
  };
  /**
   * Fetches the application ID and owner from the server.
   * @async
   * @function getID
   * @returns {Promise<void>}
   */
  const getID = async () => {
    await fetchWithTimeout(
      `https://prod-cmdbbackend.azurewebsites.net/getApplicationId?applicationName=${encodeURIComponent(
        applicationName
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setApplicationId(data.applicationId);
        setApplicationOwner(data.applicationOwner);
      })
      .catch((error) => {
        console.error("Error fetching Application ID:", error);
      });
  };
  useEffect(() => {
    props.setProgress(90);
    props.setProgress(100);
    getDimensions();
    if (autosearch && applicationName !== "") {
      inputRef.current.click();
      setautosearch(false);
    }
    // inputRef.current.click();
    // setTimeout(() => {
    //   getToken()
    // }, 2000);
    // getToken()
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      getDimensions();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    console.log(graphData);
  }, []);
  // const getTableData=async ()=>{
  //   // getSuggestions(applicationName);
  //   if(sessionStorage.getItem("table")!==null){
  //     const data=await JSON.parse(sessionStorage.getItem("table"))
  //     await setTable1(data)
  //     console.log(table1)
  //     setshowTable(true)
  //   }
  // }
  // const getSuggestions = async (applicationName) => {
  //   const response = await fetchWithTimeout(`https://prod-cmdbbackend.azurewebsites.net/?applicationName=${applicationName}`);
  //   const result = await response.json();
  //   await setSuggestions(result);
  //   console.log(suggestions)
  // }
  function indexOf2d(array2d, itemtofind) {
    return (
      [].concat
        .apply([], [].concat.apply([], array2d))
        .map((item) => item.toLowerCase())
        .indexOf(itemtofind.toLowerCase()) !== -1
    );
  }

  function indexOf2d_appID(array2d, itemtofind, itemtofind2) {
    const itemtofind2Str = itemtofind2.toString();
    console.log("Item to find 2", itemtofind2);
    console.log("Array2d", array2d);
    return array2d.some(
      (row) =>
        row
          .map((item) => (item ? item.toLowerCase() : ""))
          .includes(itemtofind.toLowerCase()) &&
        row
          .map((item) => (item ? item.toLowerCase() : ""))
          .includes(itemtofind2Str.toLowerCase())
    );
  }

  function searchValuesInRow(table, valuea, valueb) {
    console.log("Table", table, valuea, valueb);
    valuea = valuea.toLowerCase();
    valueb = valueb.toLowerCase();
    return table.some(
      (row) =>
        row.map((cell) => cell.toLowerCase()).includes(valuea) &&
        row.map((cell) => cell.toLowerCase()).includes(valueb)
    );
  }
  /**
   * Handles the commit action by sending a POST request to the server.
   * Updates the progress, displays toast messages based on the response,
   * and triggers additional actions if necessary.
   * @returns {Promise<void>} A promise that resolves when the commit action is completed.
   */
  const handleCommit = async () => {
    props.setProgress(30);
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/commit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          requested_by: graphData.displayName,
          applicationName:
            relationshipCategorySelected.value === "server_to_app"
              ? inputserverName
              : applicationName,
          relationshipCategory: relationshipCategorySelected.label,
        }),
      });
      props.setProgress(70);
      const result = await response.json();
      if (result.includes("Success")) {
        await fetchOwnership();
        toast.success("Confirmed sucessfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setshowCommit(false);
        setserverName("");
        setDisableCertify(true);
        await fetchData();
        await fetchAppData();
        await fetchServerData();
      } else if (result.includes("Exception: No Owner")) {
        toast.error("Restricted: Application has not CI owner assigned", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setshowCommit(false);
      } else {
        toast.error("Confirm Failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      props.setProgress(100);
      setTimeout(() => {
        if (!rated) {
          props.setOpen(true);
          setRated(true);
        }
      }, 2000);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    await fetchData();
    await fetchAppData();
  };
  const handleSuggestionBadge = async (item) => {
    await setSuggestionSelected(true);
    await setapplicationName(item.item);
    // await handleSearchSubmit(new Event('submit',));
    inputRef.current.click();
    setDisplaySuggestion(false);
  };
  /**
   * Fetches the rate from the server and updates the state accordingly.
   * @async
   * @function showRate
   * @returns {Promise<void>}
   */
  const showRate = async () => {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/showrate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ user: graphData?.displayName }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        console.log("Rate Result", result);
        if (result === "Success") {
          console.log("IF CONDITION");
          setRated(false);
        } else {
          console.log("ELSE CONDITION");
          setRated(true);
        }
        console.log("Rate Value", rated);
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Show Rate Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  /**
   * Handles the certification process.
   * @async
   * @function handlecertify
   * @returns {Promise<void>}
   */
  const handlecertify = async () => {
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/certify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          logged_in_user: graphData.displayName,
          relationship_category: relationshipCategorySelected.value,
        }),
      });
      const data = await response.json();
      if (
        data.message === "Certified" &&
        !/^[Aa]\d{2,}$/.test(applicationName)
      ) {
        await fetchOwnership();
        toast.info(applicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowCertify(false);
      } else if (/^[Aa]\d{2,}$/.test(applicationName)) {
        toast.info(data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowCertify(false);
      }

      // setCertificationSuccessful(true);
      else if (data.message === "Invalid relationship category") {
        toast.error("Please select a relationship category", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  /**
   * Handles the subscription process.
   * @async
   * @function handleSubscribe
   * @returns {Promise<void>}
   */ 
  const handleSubscribe = async () => {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            email: graphData?.mail,
          }),
        }
      );
      const result = await response.json();
      if (result === "Subscription Successful") {
        toast.success("Application has been successfully subscribed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowSubscribe(false);
        setShowUnsubscribe(true);
      } else {
        toast.error("Subscription failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      toast.error("Subscription failed", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
    /**
   * Handles the unsubscription process.
   * @async
   * @function handleUnsubscribe
   * @returns {Promise<void>}
   */
  const handleUnsubscribe = async () => {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/unsubscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            email: graphData?.mail,
          }),
        }
      );
      const result = await response.json();
      if (result === "Unsubscription Successful") {
        toast.success("Application has been successfully unsubscribed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowSubscribe(true);
        setShowUnsubscribe(false);
      } else {
        toast.error("Unsubscription failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
      toast.error("Unsubscription failed", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const getDimensions = () => {
    if (window.innerWidth == 2048) {
      setWidth("126px");
    }
    if (window.innerWidth == 1920) {
      setWidth("63px");
    }
    if (window.innerWidth == 1707) {
      setWidth("33px");
    }
    if (window.innerWidth == 1536) {
      return "31px";
    }
  };
  const handleRelationshipCategory = async (option) => {
    console.log("option", option);
    console.log("relationshipCategorySelected", relationshipCategorySelected);
    if (
      option.value === "server_to_app" ||
      relationshipCategorySelected.value === "server_to_app"
    ) {
      console.log("INSIDE IF");
      setautosearch(false);
      await setTabIndex(0);
      // setapplicationName("");
      // setInputserverName("");
      if (option.value === "server_to_app") {
        setCertificationSuccessful(false);
        setShowCertify(false);
        setShowSubscribe(false);
        setShowUnsubscribe(false);
        const suggestionBox = document.getElementById("suggestion-box");
        suggestionBox.style.display = "none";
        setSelectedServerOption([]);
      }
    } else if (
      /^[Aa]\d{2,}$/.test(applicationName) &&
      (option.value == "app_to_app" || option.value == "app_to_cloud")
    ) {
      console.log("INSIDE app to app");
      await setautosearch(false);
      await setapplicationName("");
      console.log("autosearch", autosearch);
    } else {
      console.log("INSIDE ELSE");
      setautosearch(true);
    }
    setautosearch(false);

    await setRelationshipCategorySelected(option);
    // if (option.value === "app_to_server") {
    //   setTabIndex(0);
    // }

    if (
      applicationName !== "" &&
      option.value !== "server_to_app" &&
      relationshipCategorySelected.value !== "server_to_app"
    ) {
      console.log(/^[Aa]\d{2,}$/.test(applicationName));
      console.log(option.value);

      if (
        /^[Aa]\d{2,}$/.test(applicationName) &&
        (option.value == "app_to_app" || option.value == "app_to_cloud")
      ) {
        console.log("Inside app to app");
      } else {
        inputRef?.current.click();
      }
      console.log(`Option selected:`, option.label);
      console.log(/^[Aa]\d{2,}$/.test(applicationName));
    }
  };
  const handleRelationshipType = (option) => {
    setRelationshipType(option);
  };
  const handleTabClick = (index) => {
    setTabIndex(index);
  };
  const handleCreateApplicationSubmit = async (e) => {
    // setshowCommit(true)
    e.preventDefault();
    if (selectedCreateSuggestion) {
      props.setProgress(50);
      console.log("Child Application", applicationinputValue);
      if (applicationinputValue.length == 0) {
        toast.error("Application Name Cannot Be Empty", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else if (
        relationshipTypeSelected.value == "" ||
        relationshipTypeSelected.value == null
      ) {
        toast.error("Please select a relationship type", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else {
        if (
          searchValuesInRow(
            table5,
            applicationinputValue,
            relationshipTypeSelected.value
          )
        ) {
          console.log("Relationship exists");
          toast.error(
            applicationinputValue +
              " for " +
              relationshipTypeSelected.value +
              " Relationship Exists",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          props.setProgress(100);
        } else {
          console.log("else part");
          props.setProgress(50);
          // const application =applicationName.split(' ').join('+');
          try {
            const response = await fetchWithTimeout(
              `https://prod-cmdbbackend.azurewebsites.net/apptoappcreate`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                  applicationName: applicationName,
                  serverName: applicationinputValue,
                  requestedby: graphData?.displayName,
                  relationtype: relationshipTypeSelected.value,
                }),
              }
            );
            props.setProgress(70);
            const data = await response.json();
            props.setProgress(100);
            console.log(data.message);
            if (data.message == "Data processed successfully") {
              toast.success(applicationinputValue + " processed successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              toast.info("You can confirm your changes", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setshowCommit(true);
              setapplicationInputValue("");
            } else if (data.message.includes("Request for relation")) {
              console.log(applicationinputValue + " Relationship exists");
              toast.error(data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              props.setProgress(100);
            } else if (data?.message.includes("Restricted:")) {
              toast.error(data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              props.setProgress(100);
            } else {
              toast.warning(applicationinputValue + " " + data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } catch (error) {
            if (error.message === "Timeout") {
              toast.error("Request Timed Out", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              console.log("APp to app create error", error);
              toast.error("Server Error", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
          //   var temp =await [...new Set(table1.map(row => row[2]))];
          //  await setuniqueValues(temp)
          //   console.log(uniqueValues)
        }
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubscriptionNameSubmit = async (e) => {
    e.preventDefault();
    if (subscriptionName.length == 0) {
      toast.error("Subscription Name Cannot Be Empty", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      props.setProgress(20);

      await toast.promise(
        (async () => {
          try {
            const response = await fetch(
              `https://prod-cmdbbackend.azurewebsites.net/createclouddata?subscriptionName=${encodeURIComponent(
                subscriptionName
              )}&applicationName=${encodeURIComponent(
                applicationName
              )}&username=${encodeURIComponent(graphData?.displayName)}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            props.setProgress(50);
            const data = await response.json();
            setCloudData(data);
            if (
              data?.resourceGroups.length === 0 &&
              data?.certified_data.length === 0
            ) {
              toast.error("No data found for the application", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              console.log("No data found for the application");
              props.setProgress(100);
              if (data?.isciowner === true) {
                setshowcreatebutton(true);
                console.log("true");
              } else if (data?.isciowner === false) {
                console.log("false");
                setshowcreatebutton(false);
              }
              return;
            }
            if (data?.certificationDetails.includes("Certified")) {
              setCertificationSuccessful(true);
              setCertificationResult(data?.certificationDetails);
            }
            if (data?.isciowner === true) {
              setshowcreatebutton(true);
              console.log("true");
            } else if (data?.isciowner === false) {
              console.log("false");
              setshowcreatebutton(false);
            }
            props.setProgress(100);
          } catch (error) {
            // alert("Error fetching data");

            props.setProgress(100);
            throw error; // Rethrow the error to be caught by toast.promise error handler
          }
        })(),
        {
          pending: "Fetching data",
          error: "Incorrect Subscription Name",
        }
      );
    }
  };

  const handleServerCreateSubmit = async (e) => {
    // setshowCommit(true)
    e.preventDefault();
    if (selectedCreateSuggestion) {
      props.setProgress(50);
      console.log("Child Application", applicationinputValue);
      if (applicationinputValue.length == 0) {
        toast.error("Application Name Cannot Be Empty", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else {
        props.setProgress(50);
        let sucess_entry = 0;

        for (let i = 0; i < selectedServerOption.length; i++) {
          const option = selectedServerOption[i];

          if (searchValuesInRow(table8, option.value, applicationinputValue)) {
            console.log("Relationship exists");
            toast.error(
              `${applicationinputValue} - ${option.value} Relationship Exists`,
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            props.setProgress(100);
          } else {
            props.setProgress(50);
            // const application =applicationName.split(' ').join('+');
            try {
              const response = await fetchWithTimeout(
                `https://prod-cmdbbackend.azurewebsites.net/create?applicationName=${encodeURIComponent(
                  applicationinputValue
                )}&serverName=${option.value}&requestedby=${
                  graphData.displayName
                }&graph_access_token=${token}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );

              props.setProgress(70);
              const data = await response.json();
              props.setProgress(100);
              console.log(data.message);

              if (data.message == "Data processed successfully") {
                toast.success(
                  `${applicationinputValue} - ${option.value}  processed successfully`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                sucess_entry += 1;
              } else if (data.message.includes("Request for relation")) {
                console.log(
                  `${applicationinputValue} - ${option.value} Relationship exists`
                );
                toast.error(
                  `${applicationinputValue} - ${option.value} Relationship exists`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              } else if (data?.message.includes("Exception: No Owner")) {
                toast.error(
                  "Restricted: Application has not CI owner assigned",
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                props.setProgress(100);
              } else {
                toast.warning(
                  `${applicationinputValue} - ${option.value}  ${data.message}`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              }
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Server Error", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          }
          //   var temp =await [...new Set(table1.map(row => row[2]))];
          //  await setuniqueValues(temp)
          //   console.log(uniqueValues)
          if (i === selectedServerOption.length - 1 && sucess_entry > 0) {
            toast.info("You can confirm your changes", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setshowCommit(true);
          }
        }
        props.setProgress(100);
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <div
        className="create-form"
        id="createDiv"
        style={{
          paddingBottom:
            relationshipCategorySelected.value === "app_to_app"
              ? "75px"
              : "20px",
        }}
      >
        {relationshipCategorySelected.value == "app_to_server" && (
          <form className="create" onSubmit={handleCreateSubmit}>
            <label htmlFor="fname">Application Name</label>
            {/^[Aa]\d{2,}$/.test(applicationName) ? (
              <Select
                id="applicationName"
                value={applicationNameSelected}
                options={
                  typeof appStatus === "object" && appStatus !== null
                    ? Object.keys(appStatus).map((key) => ({
                        value: key,
                        label: key,
                      }))
                    : []
                }
                onChange={(selected) => {
                  console.log(selected);
                  setapplicationNameSelected(selected);
                }}
                isClearable
                styles={{
                  container: (base) => ({ ...base, flex: 1 }),
                }}
              />
            ) : (
              <input
                type="text"
                id="fname"
                name="applicationName"
                value={applicationName}
                readOnly={true}
                placeholder=" "
                style={{ backgroundColor: "#D3D3D3" }} // Different background color
              />
            )}
            <label htmlFor="lname">Related To</label>
            <input
              type="text"
              id="lname"
              name="serverName"
              placeholder="Add upto 10 servers separated by comma"
              onChange={handleserverNameChange}
              value={serverName}
            />
            <input type="submit" value="Submit" />
            <button id="cancel" type="button" onClick={hideCreate}>
              Cancel
            </button>
          </form>
        )}
        {relationshipCategorySelected.value == "app_to_app" && (
          <form
            className="create"
            onSubmit={handleCreateApplicationSubmit}
            style={{
              width: "100%",
            }}
          >
            <label htmlFor="fname">Application Name</label>
            <input
              type="text"
              id="fname"
              name="applicationName"
              value={applicationName}
              readOnly={true}
              placeholder=" "
              style={{ backgroundColor: "#D3D3D3" }}
            />
            <label htmlFor="selectRelationshipType">Relationship Type:</label>
            <span
              style={{
                color: "red",
                marginLeft: "3px",
              }}
            >
              &#42;
            </span>
            <Select
              id="selectRelationshipType"
              value={relationshipTypeSelected}
              onChange={handleRelationshipType}
              options={relationshipType}
              isSearchable={false}
              isClearable={false}
              styles={{
                container: (base) => ({ ...base, flex: 1, zIndex: 2 }),
              }}
            />

            <label htmlFor="applicationInputName">Related To</label>
            <div style={{ width: "100%", position: "relative" }}>
              <input
                type="text"
                id="applicationInputName"
                name="applicationInputName"
                onChange={handleinputApplicationNameChange}
                value={applicationinputValue}
                style={{
                  marginBottom: "0px",
                  height: "38px",
                }}
              />
              <div
                id="suggestion-container2"
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  backgroundColor: "white",
                  zIndex: 10,
                  position: "absolute",
                  display: "none",
                  borderTopLeftRadius: "0px",
                  overflowY: "auto",
                }}
              ></div>
            </div>
            <input type="submit" value="Submit" />
            <button id="cancel" type="button" onClick={hideCreate}>
              Cancel
            </button>
          </form>
        )}

        {relationshipCategorySelected.value == "app_to_cloud" && (
          <>
            <form className="create" onSubmit={handleSubscriptionNameSubmit}>
              <label htmlFor="fname">Application Name</label>
              <input
                type="text"
                id="fname"
                name="applicationName"
                value={applicationName}
                readOnly={true}
                placeholder=" "
                style={{ backgroundColor: "#D3D3D3" }}
              />
              <label htmlFor="lname">Subscription Name</label>
              <input
                type="text"
                id="lname"
                name="serverName"
                onChange={handleSubscriptionNameChange}
                value={subscriptionName}
              />
              <input type="submit" value="Submit" />
              <button id="cancel" type="button" onClick={hideCreate}>
                Cancel
              </button>
            </form>
          </>
        )}
        {relationshipCategorySelected.value == "server_to_app" && (
          <form
            className="create"
            onSubmit={handleServerCreateSubmit}
            style={{
              width: "100%",
            }}
          >
            <label htmlFor="fname">Server Name</label>
            {/* <input
              type="text"
              id="fname"
              name="applicationName"
              // value={applicationName}
              // readOnly={true}
              placeholder=" "
              // style={{ backgroundColor: "#D3D3D3" }}
            /> */}
            <Select
              isMulti
              id="selectServerOption"
              value={selectedServerOption}
              onChange={handleMultiSelectServerChange}
              options={serverOptions}
              isDisabled={serverOptions.length === 0}
              placeholder="Select servers..."
              styles={{ container: (base) => ({ ...base, flex: 1 }) }}
            />

            <label htmlFor="lname">Related To</label>
            <div style={{ width: "100%", position: "relative" }}>
              <input
                type="text"
                id="applicationInputName"
                name="applicationInputName"
                onChange={handleinputApplicationNameChange}
                value={applicationinputValue}
                style={{
                  marginBottom: "0px",
                  height: "38px",
                }}
              />
              <div
                id="suggestion-container2"
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  backgroundColor: "white",
                  zIndex: 10,
                  position: "absolute",
                  display: "none",
                  borderTopLeftRadius: "0px",
                  overflowY: "auto",
                }}
              ></div>
            </div>
            <input type="submit" value="Submit" style={{ marginTop: "20px" }} />
            <button
              id="cancel"
              type="button"
              onClick={hideCreate}
              style={{ marginTop: "20px" }}
            >
              Cancel
            </button>
          </form>
        )}
      </div>
      <div className="container1">
        <h2>Search For CI Relationship</h2>
      </div>
      <div className="search-form">
        <form onSubmit={handleSearchSubmit} style={{ marginTop: "30px" }}>
          <div
            style={{
              margin: "20px 0px",
              display: "flex",
              alignItems: "center",
              width: "475px",
            }}
          >
            <label
              htmlFor="selectRelationshipCategory"
              style={{ marginRight: "5px" }}
            >
              Relationship Category:
            </label>
            <Select
              id="selectRelationshipCategory"
              value={relationshipCategorySelected}
              onChange={handleRelationshipCategory}
              options={relationshipCategory}
              isSearchable={false}
              isClearable={false}
              styles={{
                container: (base) => ({ ...base, flex: 1, zIndex: 2 }),
              }}
            />
          </div>

          <label htmlFor="application-name">
            {" "}
            {relationshipCategorySelected.label === "Server to App"
              ? "Server Name:"
              : !applicationManagerDisplay
              ? "Application ID:"
              : "Application Name:"}{" "}
          </label>
          <div
            style={{
              display: "inline-flex",
              width: "300px",
              flexDirection: "column",
            }}
          >
            <input
              style={{ width: "100%" }}
              className="search-input"
              type="text"
              name="applicationName"
              id="application-name"
              onChange={handleapplicationNameChange}
              value={
                relationshipCategorySelected.value === "server_to_app"
                  ? inputserverName
                  : applicationName
              }
              // placeholder="Add upto 10 servers separated by comma"
              placeholder={
                relationshipCategorySelected.label === "Server to App"
                  ? "Advised to search up to 50 servers, comma-separated."
                  : relationshipCategorySelected.label === "App to Server"
                  ? "Application Name/ID"
                  : "Application Name"
              }
              autoComplete="off"
            />
 
            <div className="suggestion-container">
              <div
                style={{ width: "300px", position: "absolute" }}
                id="suggestion-box"
                className="suggestion-box"
              ></div>
            </div>
          </div>
          <input
            type="submit"
            style={{ backgroundColor: "#42B0D5" }}
            value="Search"
            ref={inputRef}
          ></input>
          {!isRetired && certificationSuccessful && (
            <span
              data-tooltip-id="certified"
              data-tooltip-content={certificationResult}
              data-tooltip-place="top-start"
            >
              {/* <FcApproval
                style={{ marginLeft: "10px", fill: "#FFBF00" }}
                size={30}
              /> */}
              {showTooltip?.includes("Certification expire") ? (
                <FaExclamationCircle
                  size={20}
                  style={{
                    color: "#F28C28",
                    marginRight: "3px",
                    marginLeft: "5px",
                  }}
                />
              ) : (
                <FaCheckCircle
                  size={20}
                  style={{
                    color: "#198754",
                    marginLeft: "10px",
                  }}
                />
              )}
              <span style={{ marginLeft: "3px", fontSize: "13px" }}>
                {certificationResult}
              </span>
              {/* <Tooltip id="certified" /> */}
            </span>
          )}
        </form>

        <div></div>
        {subscriptionSuggestions.length > 0 &&
          relationshipCategorySelected.value == "app_to_cloud" && (
            <div
              style={{
                marginTop: "20px",
                fontSize: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "20px" }}>Suggested Subscriptions:</span>
              <select
                className="form-select"
                aria-label="Default select example"
                id="subscriptions"
                name="subscriptions"
                style={{
                  width: "fit-content",
                  padding: " 5px",
                  fontSize: "1rem",
                  marginLeft: "5px",
                  minWidth: "200px",
                }}
                onChange={async (e) => {
                  console.log("selected", e.target.value);
                  props.setProgress(20);
                  await toast
                    .promise(
                      async () => {
                        const response = await fetch(
                          `https://prod-cmdbbackend.azurewebsites.net/createclouddata?subscriptionName=${encodeURIComponent(
                            e.target.value
                          )}&applicationName=${encodeURIComponent( 
                            applicationName
                          )}&username=${encodeURIComponent(graphData?.displayName)}`,
                          {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          }
                        );
                        props.setProgress(50);
                        if (!response.ok) {
                          throw new Error("Failed to fetch cloud data");
                        }
                        const data = await response.json();
                        if (
                          data?.resourceGroups.length === 0 &&
                          data?.certified_data.length === 0
                        ) {
                          toast.error("No data found for the application", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          console.log("No data found for the application");
                          props.setProgress(100);
                          return;
                        }
                        setCloudData(data);
                        props.setProgress(100);
                        if (data?.certificationDetails.includes("Certified")) {
                          setCertificationSuccessful(true);
                          setCertificationResult(data?.certificationDetails);
                        }
                        if (data?.isciowner === true) {
                          setshowcreatebutton(true);
                          console.log("true");
                        } else if (data?.isciowner === false) {
                          console.log("false");
                          setshowcreatebutton(false);
                        }

                        return data; // Resolve the promise with data
                      },
                      {
                        pending: "Fetching data",
                        error: "Incorrect Subscription Name",
                      }
                    )
                    .catch((error) => {
                      props.setProgress(100);
                      // Handle any additional error logic here
                    });
                }}
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                {subscriptionSuggestions?.map((item, index) => {
                  return (
                    <option key={index} value={item.accountId}>
                      {item.accountName.substring(
                        0,
                        item.accountName.length - 25
                      )}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

        <div className="container2" style={{ marginTop: "30px" }}>
          <h5
            style={{
              display: "inline-block",
              paddingRight: "5px",
            }}
          >
            Create a new relationship
          </h5>
          <span
            data-tooltip-id="create"
            data-tooltip-content={
              isRetired
                ? "This application is retired"
                : !showOwner
                ? "Restricted: Application has not CI owner assigned"
                : relationshipCategorySelected.label === "Server to App"
                ? "Search for a server first"
                : "Search for an application first"
            }
            data-tooltip-hidden={
              (relationshipCategorySelected.value === "server_to_app"
                ? inputserverName
                : applicationName) == "" ||
              !showOwner ||
              isRetired
                ? false
                : true
            }
            data-tooltip-place="bottom-start"
            style={{
              cursor:
                isRetired ||
                (relationshipCategorySelected.value === "server_to_app"
                  ? inputserverName
                  : applicationName) === "" ||
                !showOwner
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            <button
              type="button"
              onClick={showCreate}
              id="create-button"
              className="btn btn-success "
              disabled={!showOwner ? true : !showcreatebutton}
              style={{
                pointerEvents:
                  isRetired ||
                  !showOwner ||
                  (relationshipCategorySelected.value === "server_to_app"
                    ? inputserverName
                    : applicationName) == ""
                    ? "none"
                    : "auto",
              }}
            >
              Create
            </button>
            <Tooltip id="create" />
          </span>
      { applicationManagerDisplay &&   <span
          data-tooltip-id="bulkupload"
          data-tooltip-content={
            relationshipCategorySelected.value == "app_to_server"
              ? ""
              :"This feature is only available for App to Server relationship category"
          }
          data-tooltip-hidden={
            relationshipCategorySelected.value == "app_to_cloud" || relationshipCategorySelected.value == "app_to_app"
              ? false
              : true
          }
          data-tooltip-place="bottom-start"
          >
          <button
                className="btn btn-success"
                style={{
                  backgroundColor: "#42B0D5",
                  borderColor: "#42B0D5",
                  color: "white",
                  marginLeft:"12px",
                  cursor: relationshipCategorySelected.value == "app_to_cloud" || relationshipCategorySelected.value == "app_to_app" ? "not-allowed" : "pointer",
                }}
                onClick={(event) => {
                  if (!(relationshipCategorySelected.value == "app_to_cloud" || relationshipCategorySelected.value == "app_to_app")) {
                    props.setbulkuploadmodal(true);
                  }
                  event.preventDefault();
                }}
                disabled={relationshipCategorySelected.value == "app_to_cloud" || relationshipCategorySelected.value == "app_to_app" ? true : false}
               
              >
                Bulk Upload
              </button>
              <Tooltip id="bulkupload" />
              </span> }
          {showCertify && (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="certify-tooltip"
                  data-tooltip-content={
                    certificationSuccessful ? "" : "Approvals Pending"
                  }
                  data-tooltip-hidden={!disableCertify}
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: { disableCertify } ? "not-allowed" : "pointer",
                  }}
                >
                  <button
                    onClick={handlecertify}
                    disabled={disableCertify}
                    className="btn btn-success "
                    style={{
                      backgroundColor: "#42B0D5",
                      borderColor: "#42B0D5",
                    }}
                  >
                    Certify
                  </button>
                  {/* <Tooltip id="certify-tooltip" /> */}
                </div>
                <div
                  data-tooltip-id="info"
                  data-tooltip-content={showTooltip}
                  data-tooltip-place="bottom-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="info" />
              </div>
            </div>
          )} 
          {showSubscribe && !showUnsubscribe && (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* Subscribe Button */}
                <div
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  <button
                    onClick={handleSubscribe}
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#42b0d5", 
                      borderColor: "#42b0d5",
                      fontSize:"1rem"
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          )}
          {showUnsubscribe && !showSubscribe &&  (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* Unsubscribe Button */}
                <div
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  <button
                    onClick={handleUnsubscribe}
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#cf482e", 
                      borderColor: "#cf482e",
                    }}
                  >
                    Unsubscribe
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {displaySuggestion && (
          <div className="relevant-box">
            {" "}
            Did you mean by:
            {suggestionList.map((item, index) => {
              return (
                <span
                  key={index}
                  className="badge"
                  onClick={() => handleSuggestionBadge({ item })}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </div>
      <div style={{ marginTop: "55px" }}>
        <Tabs selectedIndex={tabIndex} onSelect={handleTabClick} style={{marginTop:"5%"}}>
          <div className="content" style={{ marginBottom: "-20px" }}>
            {(table1?.length > 0 ||
              data?.length > 0 ||
              table5?.length > 0 ||
              cloudData?.resourceGroups?.length > 0 ||
              appData?.length > 0 ||
              table8?.length > 0 ||
              serverData?.length > 0) && (
              <TabList>
                <Tab>Table View</Tab>
                <Tab
                  disabled={
                    relationshipCategorySelected.value === "app_to_server" ||
                    relationshipCategorySelected.value === "app_to_cloud" ||
                    relationshipCategorySelected.value === "server_to_app"
                  }
                >
                  <span
                    data-tooltip-id="TreeView"
                    data-tooltip-content={`Tree view is not available for ${relationshipCategorySelected.label} relationship category`}
                    data-tooltip-hidden={
                      relationshipCategorySelected.value === "app_to_server" ||
                      relationshipCategorySelected.value === "server_to_app"
                        ? false
                        : true
                    }
                    data-tooltip-place="bottom-start"
                    style={{
                      cursor:
                        relationshipCategorySelected.value ===
                          "app_to_server" ||
                        relationshipCategorySelected.value === "app_to_cloud" ||
                        relationshipCategorySelected.value === "server_to_app"
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    Tree View
                    <Tooltip id="TreeView" />
                  </span>
                </Tab>
              </TabList>
            )}
          </div>

          <TabPanel>
            {relationshipCategorySelected.value === "app_to_server" &&
            (table1?.length > 0 || data?.length > 0) ? (
              <Table
                Header1="App Name"
                Header2={
                  /^[Aa]\d{2,}$/.test(applicationName)
                    ? "Environment"
                    : "Relationship Type"
                }
                Header3="Related To"
                Header4="Server Type"
                Header5="Server Env"
                Header6="Server Status"
                Header7="Server Role"
                Header8="Server Vendor"
                FilterIndex={3}
                FilterIndex2={6}
                setshowCommit={setshowCommit}
                applicationId={applicationId}
                applicationOwner={applicationOwner}
              />
            ) : (
              " "
            )}
            {relationshipCategorySelected.value === "app_to_app" &&
            (table5?.length > 0 || appData?.length > 0) ? (
              <Table5
                Header1="CI Name"
                Header2="Relationship Type"
                Header3="Related To"
                Header4="Type"
                FilterIndex={1}
                setshowCommit={setshowCommit}
                applicationId={applicationId}
                applicationOwner={applicationOwner}
              />
            ) : (
              " "
            )}

            {relationshipCategorySelected.value === "app_to_cloud" &&
            (cloudData?.resourceGroups?.length > 0 ||
              cloudData?.certified_data?.length > 0) ? (
              <CloudTableFormat
                data1={cloudData}
                setProgress={props.setProgress}
                fetchCloudData={fetchCloudData}
              />
            ) : (
              " "
            )}

            {relationshipCategorySelected.value === "server_to_app" &&
            (table8?.length > 0 || serverData?.length > 0) ? (
              <Table8
                Header1="Server Name"
                Header2="Server Status"
                Header3="Server Type"
                Header4="Vendor"
                Header5="Server Env"
                Header6="Server Owner"
                Header7="Server Role"
                Header8="Related App"
                Header9="App Owner"
                FilterIndex={2}
                FilterIndex2={7}
                setshowCommit={setshowCommit}
              />
            ) : (
              " "
            )}
          </TabPanel>
          <TabPanel>
            <div
              className="content"
              style={{ height: "35vh", marginTop: "40px" }}
            >
              <Tree />
            </div>
          </TabPanel>
        </Tabs>
      </div>

      {relationshipCategorySelected.value == "app_to_server" && (
        <div
          className="commit-button"
          style={
            table1?.length === 0 && data?.length == 0
              ? { position: "absolute", top: "500px", right: "50px" }
              : {
                  position: "absolute",
                  marginTop: "10px",
                  right:
                    window.innerWidth -
                    tableRightBoundary -
                    (window.innerWidth - document.documentElement.scrollWidth),
                }
          }
        >
          {showCommit ? (
            <Button
              appearance="alt"
              fit="medium"
              id="primary"
              justifyItems="center"
              label="Confirm"
              name="primary"
              onClick={function noRefCheck() {
                handleCommit();
              }}
              title="Button"
              variant="filled"
            />
          ) : (
            " "
          )}
        </div>
      )}
      {relationshipCategorySelected.value == "app_to_app" && (
        <div
          className="commit-button"
          style={
            table5?.length === 0 && appData.length === 0
              ? { position: "absolute", top: "500px", right: "50px" }
              : {
                  position: "absolute",
                  marginTop: "10px",
                  right:
                    window.innerWidth -
                    tableRightBoundary -
                    (window.innerWidth - document.documentElement.scrollWidth),
                }
          }
        >
          {showCommit ? (
            <Button
              appearance="alt"
              fit="medium"
              id="primary"
              justifyItems="center"
              label="Confirm"
              name="primary"
              onClick={function noRefCheck() {
                handleCommit();
              }}
              title="Button"
              variant="filled"
            />
          ) : (
            " "
          )}
        </div>
      )}
      {relationshipCategorySelected.value == "server_to_app" && (
        <div
          className="commit-button"
          style={
            table8?.length === 0 && serverData?.length == 0
              ? { position: "absolute", top: "500px", right: "50px" }
              : {
                  position: "absolute",
                  marginTop: "10px",
                  right:
                    window.innerWidth -
                    tableRightBoundary -
                    (window.innerWidth - document.documentElement.scrollWidth),
                }
          }
        >
          {showCommit ? (
            <Button
              appearance="alt"
              fit="medium"
              id="primary"
              justifyItems="center"
              label="Confirm"
              name="primary"
              onClick={function noRefCheck() {
                handleCommit();
              }}
              title="Button"
              variant="filled"
            />
          ) : (
            " "
          )}
        </div>
      )}

      <div style={{ marginTop: "90px", visibility: "hidden" }}>""</div>
    </div>
  );
}