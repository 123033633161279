import { ToastContainer, toast } from "react-toastify";
 
export const handleShowSubscribe = async (applicationName, graphData, relationshipCategorySelected, setShowSubscribe, setShowUnsubscribe) => {
  try {
    const response = await fetch("https://prod-cmdbbackend.azurewebsites.net/checkSubscriptionStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ app_name: applicationName, email: graphData?.mail, relationship_category: relationshipCategorySelected, }),
    });
    if (response.ok) {
      const subscriptionResult = await response.json();
      console.log(subscriptionResult);
      const subscriptionStatus = subscriptionResult.subscription_status;
      if (subscriptionStatus === "Subscribed") {
        setShowSubscribe(false);
        setShowUnsubscribe(true);
      } else if (subscriptionStatus === "Not Subscribed") {
        setShowSubscribe(true);
        setShowUnsubscribe(false);
      }
    }
  } catch (error) {
    console.error("Failed to fetch subscription status:", error);
  }
};
 
export const handleSubscribe = async (applicationName, graphData) => {
  try {
    const response = await fetch("https://prod-cmdbbackend.azurewebsites.net/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ app_name: applicationName, email: graphData?.mail, }),
    });
    const result = await response.json();
    if (result === "Subscription Successful") {
      toast.success(`${applicationName} - Subscribed Successfully`);
    }
  } catch (error) {
    toast.error("Subscription failed");
  }
 
};
 
export const handleUnsubscribe = async (applicationName, graphData) => {
  try {
    const response = await fetch("https://prod-cmdbbackend.azurewebsites.net/unsubscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ app_name: applicationName, email: graphData?.mail, }),
    });
    const result = await response.json();
    if (result === "Unsubscription Successful") {
      toast.success(`${applicationName} - Unsubscribed Successfully`);
    }
  } catch (error) {
    toast.error("Unsubscription failed");
  }
};
 
 