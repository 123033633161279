import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import { TableContext } from "../context";
import { FaCheckCircle } from "react-icons/fa";
import DeleteConfirmation from "./DeleteConfirmation";
import { Icon } from "@anchor/react-components/dist/lib/components";
import { toast } from "react-toastify";

function Row(props) {
  const {
    row,
    checkedRows,
    setCheckedRows,
    certified,
    subscriptionName,
    isciowner,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const {
    graphData,
    setGraphData,
    fetchUserDetails,
    fetchWithTimeout,
    applicationName,
  } = useContext(TableContext);
  const handleDeleteClick = async () => {
    console.log("row", row);
    setshowConfirmation(false);

    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/deleteclouddata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            checkedRows: row,
            applicationName: applicationName,
          }),
        }
      );

      toast.success("Deleted Successfully.Kindly search again to see updated changes", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch {
      // Handle error
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      // Add row information to selectedRows
      setCheckedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      // Remove row information from selectedRows
      setCheckedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (checkedRows) =>
            checkedRows.resourceGroupDetails.name !==
            row.resourceGroupDetails.name
        )
      );
    }
    console.log("checkedRows", checkedRows);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (row.resources && row.resources.length > 0) {
                setOpen(!open);
              }
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          sx={{ width: "450px" }}
        >
          {row.resourceGroupDetails.subscription_name}
           </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          sx={{ width: "100px" }}
        >
          {row.resourceGroupDetails.name}
        </TableCell>
        <TableCell align="center">
          {row.resourceGroupDetails.regionName}
        </TableCell>
        <TableCell align="center">
          {row.resourceGroupDetails.cloudType}
        </TableCell>
        <TableCell align="center">
          {row.resourceGroupDetails.assetType}
        </TableCell>
        <TableCell align="center">{row.resources.length}</TableCell>

        {certified === false && isciowner === true ? (
          <>
            <TableCell align="center">
              {" "}
              <input type="checkbox" onChange={handleCheckboxChange}></input>
            </TableCell>
            <TableCell align="center" />
          </>
        ) : (
          <>
            {certified === true && (
              <TableCell align="center">
                <FaCheckCircle
                  style={{ color: "green" }}
                  title="Verified"
                  size={20}
                />
              </TableCell>
            )}
            {isciowner === true && (
              <TableCell align="center" sx={{ verticalAlign: "center" }}>
                <Icon
                  name="trash"
                  size={24}
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    setshowConfirmation(true);
                  }}
                />
              </TableCell>
            )}
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}
          colSpan={9}
        >
          <Collapse
            in={open && row.resources && row.resources.length > 0}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                margin: 0,
                height: "fit-content",
                overflowY: "auto",
                maxHeight: "300px",
                width: "100%",
              }}
            >
              <Typography variant="h6" gutterBottom component="div">
                Resources
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell>Asset Type</TableCell>
                    <TableCell>Cloud Type</TableCell>
                    <TableCell align="right">Region</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.resources.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{historyRow.name}</TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.assetType}
                      </TableCell>
                      <TableCell>{historyRow.cloudType}</TableCell>
                      <TableCell align="right">
                        {historyRow.regionName}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {showConfirmation && (
        <div className="confirmation-popup">
          <p>Are you sure you want to delete?</p>
          <div className="centered-elements">
            <button className="yes" onClick={handleDeleteClick}>
              Yes
            </button>
            <button
              className="no"
              onClick={() => {
                setshowConfirmation(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

function CloudTableFormat({ data1, setProgress, fetchCloudData }) {
  const data = data1?.resourceGroups;
  const certified_data = data1?.certified_data;
  const subscriptionDetails = data1?.subscriptionDetails;
  const certifiedSubscription = data1?.certified_subscription;
  const isciowner = data1?.isciowner;
  const certified = data1?.certified;
  // const certified= data1.certified
  const [checkedRows, setCheckedRows] = React.useState([]);
  const { applicationName, graphData } = useContext(TableContext);
  const handleCertifyClick = async () => {
    setProgress(20);

    try {
      const response = await fetch("https://prod-cmdbbackend.azurewebsites.net/certifyclouddata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          logged_in_user: graphData?.displayName,
          checkedRows,
          subscriptionDetails,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setProgress(50);

      const data = await response.json();
      if (data.includes("Failed to certify data")) {
        throw new Error("Failed to certify data");
      }
      console.log("Success:", data);
      setCheckedRows([]);
      // Uncheck all checkboxes
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      // await fetchCloudData();
      // Handle success response
      setProgress(100);
    } catch (error) {
      console.error("Error:", error);
      setCheckedRows([]);

      // Uncheck all checkboxes
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      // Handle error
    }
  };
  return (
    <div>
      <div
        style={{
          margin: "auto",
          width: "90%",
          marginTop: "30px",
          // maxHeight:"40vh",
          // overflowY: "auto",
          // maxWidth: "fit-content",
          border: "2px solid #87CEEB",
          display: "flex",
          zIndex:"-10",
        }}
      >
        {/* <div style={{width:"300px"}}>
        <TableCell
                  sx={{ borderBottom:"2px solid #87CEEB",width:"300px",fontSize:"1rem", }}
                  align="center"
                >
                  Filters
                </TableCell>

        </div> */}
       
        <TableContainer
          component={Paper}
          sx={{ width: "100%", maxHeight: "400px" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ "& .MuiTableCell-root": { fontSize: "1rem" } }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                />
                 <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                >
                  Subscription
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                >
                  Resource Group
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                >
                  Region
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                >
                  Cloud Type
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                >
                  Asset Type
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                >
                  No. of Resources
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                ></TableCell>
                <TableCell
                  sx={{ backgroundColor: "#87CEEB", color: "white" }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certified_data !== undefined &&
                Array.isArray(certified_data) &&
                certified_data
                .sort((a, b) => b.resources.length - a.resources.length)
                .map((row) => (
                  <Row
                    key={row.resourceGroupDetails?.name}
                    row={row}
                    checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    certified={true}
                    isciowner={isciowner}
                  />
                ))}
              {(isciowner === true ||
                (certified === false && isciowner === false)) &&
                data && Array.isArray(data) && 
                data.sort((a, b) => b.resources.length - a.resources.length)
                  .filter(
                    (row) =>
                      !certified_data.some(
                        (certifiedRow) =>
                          certifiedRow.resourceGroupDetails.name ===
                          row.resourceGroupDetails.name
                      )
                  )
                  .map((row) => (
                    <Row
                      key={row.resourceGroupDetails.name}
                      row={row}
                      checkedRows={checkedRows}
                      setCheckedRows={setCheckedRows}
                      certified={false}
                      isciowner={isciowner}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {checkedRows.length > 0 && (
        <div style={{ width: "90%", margin: "auto" }}>
          <button
            className="btn btn-success "
            style={{
              backgroundColor: "#42B0D5",
              borderColor: "#42B0D5",
              float: "right",
              marginTop: "8px",
            }}
            onClick={() => {
              toast.promise(
                handleCertifyClick(),
                {
                  pending: 'Certifying',
                  success: 'Certified!.Kindly search again to see updated changes',
                  error: 'Certification failed!',
                }
              );
            }}
          >
            Certify
          </button>
        </div>
      )}
    </div>
  );
}

export default CloudTableFormat;
