import { createContext, useState, useEffect, useRef } from "react";
import Table from "./components/Table";
import { callMsGraph } from "./graph";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { ToastContainer, toast } from "react-toastify";

// import jwt from 'jsonwebtoken';

export const TableContext = createContext(null);
/**
 * Context component that provides state and functions to its children components.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
/**
 */
function Context({ children }) {
  const { instance, accounts } = useMsal();
  const [table1, setTable1] = useState();
  const [applicationName, setapplicationName] = useState("");
  const [certifyapplicationName, setcertifyapplicationName] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [deletedRows, setDeletedRows] = useState([]);
  const [showCommit, setshowCommit] = useState(false);
  const [rated, setRated] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [applicationOwner, setApplicationOwner] = useState("");
  const [retired, setRetired] = useState(false);
  const [count, setCount] = useState(0);
  const [isOwner, setIsOwner] = useState("");
  const [token, setToken] = useState(null);
  const [showCertify, setShowCertify] = useState(false);
  const [disableCertify, setDisableCertify] = useState(false);
  const [certificationSuccessful, setCertificationSuccessful] = useState(false);
  const [certificationResult, setCertificationResult] = useState("");
  const [nextCertify, setNextCertify] = useState("");
  const [isciadmingrp, setisciadmingrp] = useState(false);
  const [certifypercentage, setcertifypercentage] = useState("");
  const [tableRightBoundary, setTableRightBoundary] = useState(0);
  const [tableBottomtBoundary, setTableBottomBoundary] = useState(0);
  const [appStatus, setAppStatus] = useState();
  const [retiredApps, setretiredApps] = useState();

  const [showCertify1, setShowCertify1] = useState(false);
  const [disableCertify1, setDisableCertify1] = useState(true);
  const [disableCertify1apptoapp, setDisableCertify1apptoapp] = useState(true);

  const [certificationSuccessful1, setCertificationSuccessful1] = useState(false);
  const [relationcertified, setrelationcertified] = useState(false);
  const [relationcertifiedapptoapp, setrelationcertifiedapptoapp] = useState(false);

  const [ownershipcertified, setownershipcertified] = useState(false);
  const [showcreatebutton, setshowcreatebutton] = useState(false);
  const [subscriptionSuggestions, setSubscriptionSuggestions] = useState([]);
  const [inputserverName, setInputserverName] = useState("");
  const [suggestionSelected, setSuggestionSelected] = useState(false);


  const [certificationResult1, setCertificationResult1] = useState("");
  const [nextCertify1, setNextCertify1] = useState("");
  const [appenv, setappenv] = useState("");

  const [data, setData] = useState([]);
  const [showTooltip, setShowTooltip] = useState("");
  const [showTooltip1, setShowTooltip1] = useState("");
  const [showTooltip1apptoapp, setShowTooltip1apptoapp] = useState("");

  const [showTooltip2, setShowTooltip2] = useState("");
  const [showTooltip2apptoapp, setShowTooltip2apptoapp] = useState("");


  const [ownershipTooltip, setownershipTooltip] = useState("");
  const [autosearch, setautosearch] = useState(false);
  const [table4, setTable4] = useState([]);
  const [cloudData, setCloudData] = useState([]);
  const [isCiOwnership, setIsCiOwnership] = useState(false);
  const [autosearch1, setautosearch1] = useState(false);
  const [spinnerDisplay, setSpinnerDisplay] = useState(true);
  const [macDetails,setMacDetails] = useState();
  const [ownershipdata, setownershipdata] = useState({
    managed_by: { displayValue: "", value: "", email: "" },
    owned_by: { displayValue: "", value: "", email: "" },
    sys_id: "",
    u_mvc: { displayValue: "" },
    u_engineering_manager: { displayValue: "", value: "", email: "" },
    u_sr_engineering_manager: { displayValue: "", value: "", email: "" },
    u_engineering_director: { displayValue: "", value: "", email: "" },
  });
  const [table5, setTable5] = useState();
  const relationshipCategory = [
    { value: '', label: 'Select an option', isDisabled: true },
    { value: 'app_to_server', label: 'App to Server' },
    { value: 'app_to_app', label: 'App to App' },
    { value: 'app_to_cloud', label: 'App to Cloud' },
    { value: 'server_to_app', label: 'Server to App' },

    
  ];
  const [relationshipCategorySelected, setRelationshipCategorySelected] = useState(relationshipCategory[0]);
  const [tabIndex, setTabIndex] = useState(0);
  const [appData, setAppData] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(false);
  const [isRetired, setIsRetired] = useState(false);
  const [forOtherUser, setForOtherUser] = useState(false);
  const [table8, setTable8] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [applicationManagerDisplay, setApplicationManagerDisplay] = useState(true);
  const [showOwner, setShowOwner] = useState(true);
  const [commondmodalopen, setcommondmodalopen] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showUnsubscribe, setShowUnsubscribe] = useState(false);
  useEffect(() => {
    fetchUserDetails();
    //  getToken()
    //  console.log("graphData",graphData)
  }, [accounts]);
  useEffect(() => {
    getToken();
  }, []);
  useEffect(() => {
    if(graphData?.displayName!==undefined){
      getCount();
    }
  }, [graphData?.displayName]);
  const fetchUserDetails = async () => {
    try {
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((response) => setGraphData(response));
          setToken(response.accessToken);
        });
    } catch (error) {
      console.log(error);
    }
    return true;
  };




  const fetchWithTimeout = async (url, options, timeout = 20000) => {
    try {
      const response = await Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Timeout")), timeout)
        ),
      ]);
      return response;
    } catch (error) {
      // toast.error("Request Timed Out", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      throw error;
    }
  };

    const fetchOwnership = async () => {
      setTable4([]);
      setSpinnerDisplay(true);

      try {
        // console.log("GraphData",graphData?.displayName);
        const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/applicationOwnership", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            logged_in_user: graphData?.displayName,
            logged_in_user_email: graphData?.mail,
            graph_access_token: token,
          }),
        });
        if (response.ok) {
          const result = await response.json();
          if (result.length > 0 && result != "no ownership exists") {
            setTable4(result);
            console.log("result", result);
            setForOtherUser(false);
            setIsCiOwnership(true);
            setSpinnerDisplay(false);
          }
        } else {
          setTable4([]);
          setIsCiOwnership(false);
          setSpinnerDisplay(false);

          console.log("Failed to fetch data");
        }
      } catch (error) {
        if(error.message==="Timeout"){
          toast.error("Request Timed Out", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setIsCiOwnership(false);
        setSpinnerDisplay(false);
        console.log("Error", error);
      }
      setSpinnerDisplay(false);

    };

  const getToken = async () => {
    const token=process.env.REACT_APP_SECRET
    console.log("token",token)
  //  const token= jwt.sign({ foo: 'bar' }, 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjE')
  // console.log("process.env.REACT_ACCESS_TOKEN",process.env.REACT_ACCESS_TOKEN_SECRET)

    const response = await fetchWithTimeout(`https://prod-cmdbbackend.azurewebsites.net/getToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await response.json();
    localStorage.setItem("token", data);
  };
  const handleLogout = async () => {
    try {
      // Ensure that 'instance' is your MSAL.js instance (UserAgentApplication or PublicClientApplication)
      // If using PublicClientApplication, you might not have handleRedirectCallback
      if (instance.handleRedirectCallback) {
        // Wait for any ongoing interaction to complete
        await instance.handleRedirectCallback();
      }
      // Replace 'accounts' with the correct account object
      const accounts = instance.getAllAccounts()[0]; // Retrieve the first account, adjust as needed
      // Perform the logout operation
      await instance.logoutRedirect({
        account: accounts,
        postLogoutRedirectUri: null,
      });
      // Clear the token cache after successful logout
      instance.clearCache();
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle error as needed
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/getdata1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ applicationName: applicationName, }),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("TEST", result);
        setData(result);
        // updateTable1(result);
        // console.log('table1:', table1);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };
  const getCount = async () => {
    try {
      const response = await fetchWithTimeout
      ("https://prod-cmdbbackend.azurewebsites.net/getcount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ username: graphData?.displayName }),
      });
      const result = await response.json();
      setCount(result.count);
      setIsOwner(result.isadmin);
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };

  const fetchAppData = async () => {
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/getdata2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ applicationName: applicationName, }),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("TEST", result);
        setAppData(result);
        // updateTable1(result);
        // console.log('table1:', table1);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };

  const handleOtherUserInfo = async (usernamevalue, useremailvalue) => {
    setTable4([]);
    setIsCiOwnership(false);
    setSpinnerDisplay(true);
    try {
      // console.log("GraphData",graphData?.displayName);
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/applicationOwnership", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          logged_in_user: usernamevalue,
          logged_in_user_email: useremailvalue,
          graph_access_token: token,
        }),
      });
      if (response.ok) {
        const result = await response.json();
        if (result.length > 0 && result != "no ownership exists") {
          setTable4(result);
          console.log("result", result);
          setIsCiOwnership(true);
          setForOtherUser(true);
          setSpinnerDisplay(false);
        }
      } else {
        setTable4([]);
        setIsCiOwnership(false);
        setSpinnerDisplay(false);

        console.log("Failed to fetch data");
      }
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsCiOwnership(false);
      setSpinnerDisplay(false);
      console.log("Error", error);
    }
    setSpinnerDisplay(false);

  };
  const handleCommonModalClose = () => {
    setcommondmodalopen(false);
  };

  const fetchServerData = async () => {
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/getdata3", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ applicationName: inputserverName, }),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("table8 req data", result);
        setServerData(result);
        // updateTable1(result);
        // console.log('table1:', table1);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };
  
  //  const user=fetchUserDetails()
  return (
    <TableContext.Provider
      value={{
        table1,
        setTable1,
        graphData,
        setGraphData,
        data,
        setData,
        fetchUserDetails,
        applicationName,
        setapplicationName,
        deletedRows,
        setDeletedRows,
        showCommit,
        setshowCommit,
        rated,
        setRated,
        applicationId,
        setApplicationId,
        token,
        showCertify,
        setShowCertify,
        disableCertify,
        setDisableCertify,
        certificationSuccessful,
        setCertificationSuccessful,
        certificationResult,
        setCertificationResult,
        showCertify1,
        setShowCertify1,
        disableCertify1,
        setDisableCertify1,
        certificationSuccessful1,
        setCertificationSuccessful1,
        certificationResult1,
        setCertificationResult1,
        showTooltip1,
        setShowTooltip1,
        nextCertify1,
        setNextCertify1,
        handleLogout,
        nextCertify,
        setNextCertify,
        fetchData,
        applicationOwner,
        setApplicationOwner,
        retired,
        setRetired,
        count,
        setCount,
        isOwner,
        setIsOwner,
        getCount,
        showTooltip,
        setShowTooltip,
        certifyapplicationName,
        setcertifyapplicationName,
        autosearch,
        setautosearch,
        ownershipdata,
        setownershipdata,
        isciadmingrp,
        setisciadmingrp,
        ownershipTooltip,
        setownershipTooltip,
        certifypercentage,
        setcertifypercentage,
        tableRightBoundary,
        setTableRightBoundary,
        tableBottomtBoundary,
        setTableBottomBoundary,
        appenv,
        setappenv,
        relationcertified,
        setrelationcertified,
        ownershipcertified,
        setownershipcertified,
        table4,
        setTable4,
        isCiOwnership,
        setIsCiOwnership,
        autosearch1,
        setautosearch1,
        spinnerDisplay,
        setSpinnerDisplay,
        fetchOwnership,
        showcreatebutton, setshowcreatebutton,
        showTooltip2, setShowTooltip2,
        table5, setTable5,
        relationshipCategory,
        relationshipCategorySelected, setRelationshipCategorySelected,
        tabIndex, setTabIndex,
        fetchWithTimeout,
        disableCertify1apptoapp, setDisableCertify1apptoapp,
        showTooltip2apptoapp, setShowTooltip2apptoapp,
        showTooltip1apptoapp, setShowTooltip1apptoapp,
        relationcertifiedapptoapp, setrelationcertifiedapptoapp,
        appData, setAppData,
        fetchAppData,
        selectedSuggestion, setSelectedSuggestion,
        macDetails,setMacDetails,
        isRetired, setIsRetired,
        forOtherUser, setForOtherUser,
        handleOtherUserInfo,
        cloudData, setCloudData,
        subscriptionSuggestions, setSubscriptionSuggestions,
        table8, setTable8,
        serverData, setServerData,
        fetchServerData,
        inputserverName, setInputserverName,
        applicationManagerDisplay, setApplicationManagerDisplay,
        suggestionSelected, setSuggestionSelected,
        appStatus, setAppStatus,
        retiredApps, setretiredApps,
        showOwner,
        commondmodalopen, setcommondmodalopen,handleCommonModalClose ,
        showSubscribe,
        setShowSubscribe,
        showUnsubscribe,
        setShowUnsubscribe,
setShowOwner,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
export default Context;
