import React from "react";
import { FaTimes } from "react-icons/fa";
import "./CommonModal.css"; // Assuming you have a CSS file for styles

function CommonModal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="common-modal-overlay" onClick={onClose}>
      <div className="common-modal-content" onClick={(e) => e.stopPropagation()}>
        <FaTimes
          className="close-icon"
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  );
}

export default CommonModal;