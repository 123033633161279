import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import maerskLogo from "../components/Maersk_Logo_RGB.svg";
import { TypeAnimation } from "react-type-animation";
import { TableContext } from "../context";
import Spinner from "./Spinner";
import Maintainance from "./Maintainance";
import { GoPencil } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";

const LandingPage1 = () => {
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [inputNameValue, setInputNameValue] = useState("");
  const [inputNameValue1, setInputNameValue1] = useState("");

  const [suggestionclicked, setSuggestionClicked] = useState(false);

  const [edit, setEdit] = useState(true);
  const navigate = useNavigate();
  const {
    graphData,
    table4,
    setTable4,
    isCiOwnership,
    setIsCiOwnership,
    setapplicationName,
    setautosearch,
    setcertifyapplicationName,
    autosearch1,
    setautosearch1,
    spinnerDisplay,
    setSpinnerDisplay,
    fetchOwnership,
    setRelationshipCategorySelected,
    relationshipCategory,
    selectedSuggestion, 
    setSelectedSuggestion,
    fetchWithTimeout,
    handleOtherUserInfo,
    forOtherUser,
    setForOtherUser,
  } = useContext(TableContext);
  const heightStyle = table4.length < 3 ? { height: "200px" } : {};
 
  const handleClick = () => {
    navigate("/main");
  };
 
  useEffect(() => {
    if(graphData?.displayName!==undefined){
      fetchOwnership();
    }
  }, [graphData?.displayName]);
     



  useEffect(() => {
    const tbody = document.querySelector('tbody');
    if(tbody){
      setHasVerticalScrollbar(tbody.scrollHeight > tbody.clientHeight);
    }
  }, [table4]);

  const handleInputChange = () => {
    const searchBox = document.getElementById("search-bar2");
    const suggestionBox = document.getElementById("suggestion-container2");
    const searchTerm = searchBox.value;
    setSuggestionClicked(false);
    // Make an AJAX request to fetchWithTimeout application names from the server
    if (inputNameValue.length >= 3 && suggestionclicked === false) {
      suggestionBox.innerHTML = "Loading...";
      suggestionBox.style.display = "block";
      searchBox.style.borderBottomLeftRadius = "0%";
      searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
      suggestionBox.style.padding = "10px";
      fetchWithTimeout(
        `https://prod-cmdbbackend.azurewebsites.net/fetch_user_data?UserName=${encodeURIComponent(
          inputNameValue
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data != "") {
            // Clear previous suggestions
            suggestionBox.innerHTML = "";
            // Display a maximum of 20 suggestions
            const maxSuggestions = 20;

            for (let i = 0; i < data.length && i < maxSuggestions; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              suggestionItem.innerHTML = ` ${
                data[i].name == undefined ? "No results" : data[i].name
              } <br> ${data[i].email == undefined ? "" : data[i].email}`;
              if (data[i].name != undefined) {
                suggestionItem.addEventListener("click", async function () {
                  setSuggestionClicked(true);
                  setInputNameValue1(data[i].name);
                  setInputNameValue(data[i].name);
                  handleOtherUserInfo(data[i].name, data[i].email);
                  searchBox.style.borderBottomLeftRadius = "5px";
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                });
              }
              suggestionBox.appendChild(suggestionItem);
            }
            if (data.length > 0 && searchTerm.length >= 3) {
              suggestionBox.style.display = "block";
              suggestionBox.style.padding = "0px";
              searchBox.style.borderBottomLeftRadius = "0%";
              searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
              // setSelectedSuggestion(false);
            } else {
              suggestionBox.style.display = "none";
              searchBox.style.borderBottomLeftRadius = "5px";
              searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
            }
          } else {
            // setSelectedSuggestion(true);
            suggestionBox.innerHTML = "No results found";
            suggestionBox.style.display = "block";
            suggestionBox.style.padding = "10px";
            searchBox.style.borderBottomLeftRadius = "0%";
            searchBox.style.borderBottomRightRadius = "0%"; //
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      suggestionBox.style.display = "none";
      searchBox.style.borderBottomLeftRadius = "5px";
      searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleInputChange();
    }, 500);
    return () => clearTimeout(timeout);
  }, [inputNameValue]);

  return (
    <div className="LandingPage1">
      {/* <Maintainance top={0}/> */}
      <div className="content-wrapper">
        <div className="header" style={{ width: "100%" }}>
          <img
            src={maerskLogo}
            className="maersk-logo-RGB"
            alt="Maersk logo RGB"
            height="50"
            width="120"
          />
          <div className="text-wrapper">Maersk Service Atlas</div>
          <div className="intro-group">
            <div className="text-wrapper-2">
              <TypeAnimation
                preRenderFirstString={true}
                sequence={[
                  500,
                  "Simplified way of data certification", // initially rendered starting point
                  1000,
                  "Simplified management of app server relationships",
                  1000,
                  "Simplified navigation for user-friendliness",
                  1000,
                  "Simplified visibility for valuable insights",
                  500,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </div>
            <div className="rectangle" />
            <button className="text-wrapper-3" onClick={handleClick}>
              Start Exploring
            </button>
          </div>
        </div>
        <div></div>

        {/* Add here */}

        <div className="table-container1" style={{ marginBottom: "20px" }}>
          {/* {!spinnerDisplay && ( */}
          <div className="content">
            <div className="content-item">
              {edit ? (
                <>
                  <div className={`transition-heading`}>
                    My Ownership Summary
                  </div>
                  <span
                    style={{
                      marginLeft: "5px",
                      verticalAlign: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEdit(false);
                      setInputNameValue("");
                      setInputNameValue1("");
                    }}
                  >
                    <GoPencil size={20}  title="Search for other users"/>
                  </span>{" "}
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "300px",
                      display: "inline-block",
                      position: "relative ",
                    }}
                  >
                    <input
                      id="search-bar2"
                      name="search-bar2"
                      style={{ width: "100%", height: "40px",fontSize:"22px" }}
                      type="text"
                      autoComplete="off"
                      value={inputNameValue}
                      placeholder="Search for other users"
                      onChange={(e) => {
                        setInputNameValue(e.target.value);
                      }}
                    ></input>
                    <div
                      id="suggestion-container2"
                      style={{
                        width: "100%",
                        maxHeight: "100px",
                        backgroundColor: "white",
                        zIndex: 10,
                        position: "absolute",
                        display: "none",
                        borderTopLeftRadius: "0px",
                        overflowY: "auto",

                        textAlign: "left",
                      }}
                    ></div>
                  </div>
                  <MdOutlineCancel
                    size={20}
                    style={{ marginLeft: "3px", cursor: "pointer" }}
                    onClick={() => {
                      setEdit(true);
                      fetchOwnership();
                      setSpinnerDisplay(true);
                    }}
                  />
                </>
              )}
              <div className={`transition-paragraph`} style={{marginTop:"15px"}}>
                {edit
                  ? "This is a comprehensive summary of all the applications you own along with your role, certification percentage, and any pending actions."
                  : `This is a comprehensive summary of all the applications ${inputNameValue1} own  along with their role and certification percentage.`}
              </div>
            </div>
          </div>

          {/* )} */}
          {/*  
        {!spinnerDisplay && !isCiOwnership && (
          <div className="content" style={{ margin: "50px auto" }}>
          You don't own any application
          </div>
          )} */}
        </div>
      </div>

      {spinnerDisplay && <Spinner />}

      {!spinnerDisplay ? (
        <div
          className="table-container"
          style={{
            // position: "absolute",
            // left: 0,
            // right: 0,
            margin: "0 auto",
            width: "55%",
            // bottom:130
          }}
          // style={{
          //   margin: "20px auto",
          //   overflowY: "auto",
          //   tableLayout: "fixed",
          //   borderCollapse: "collapse",
          //   minHeight: "200px",
          //   maxHeight: "fit-content",
          //   maxWidth: "fit-content",
          //   zIndex: -100,
          // }}
        >
          {table4?.length > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderRight: "2px solid #87CEEB",
                borderTop: "2px solid #87CEEB",
                borderLeft: "2px solid #87CEEB",
                fontSize: "16px",
              }}
            >
              {table4?.length > 0 ? (
                <div style={{ alignSelf: "flex-end", margin: "5px" }}>
                  No. of applications : {table4?.length}
                </div>
              ) : null}
            </div>
          ) : null}
          {table4?.length > 0 ? (
            <table id="data-table" className="custom-table">
              <thead>
                <tr
                  style={{
                    zIndex: 0,
                  }}
                >
                  <th
                    style={{
                      border: "1px solid #87CEEB",
                      wordWrap: "break-word",
                      zIndex: 0,
                    }}
                  >
                    {" "}
                    Application Name{" "}
                  </th>
                  <th
                    style={{
                      border: "1px solid #87CEEB",
                      wordWrap: "break-word",
                      zIndex: 0,
                    }}
                  >
                    {" "}
                    Role{" "}
                  </th>
                  <th
                    style={{
                      border: "1px solid #87CEEB",
                      wordWrap: "break-word",
                      width: "150px",
                      zIndex: 0,
                    }}
                  >
                    {" "}
                    Certification Percentage{" "}
                  </th>
                  {!forOtherUser && (
                    <th
                      style={{
                        border: "1px solid #87CEEB",
                        wordWrap: "break-word",
                        zIndex: 0,
                        width: "200px",
                      }}
                    >
                      {" "}
                      Pending Action{" "}
                    </th>
                  )}
                </tr>
              </thead>

              <tbody
                style={{ ...heightStyle, borderBottom: "1px solid #87CEEB" }}
              >
                {Array.isArray(table4) &&
                  table4.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td
                          style={{
                            border: "1px solid #87CEEB",
                            wordWrap: "break-word",
                          }}
                        >
                          {value[0]}
                        </td>
                        <td
                          style={{
                            border: "1px solid #87CEEB",
                            wordWrap: "break-word",
                          }}
                        >
                          {value[1].split(",").map((role, index) => (
                            <div key={index}>{role.trim()}</div>
                          ))}
                        </td>
                        <td
                          style={{
                            border: "1px solid #87CEEB",
                            wordWrap: "break-word",
                            width: "150px",
                          }}
                        >
                          <span
                            style={{
                              color:
                                value[2] === "100%"
                                  ? "green"
                                  : value[2] === "50%"
                                  ? "orange"
                                  : value[2] === "75%"
                                  ? "#FFBF00"
                                  : value[2] === "0"
                                  ? "black"
                                  : "red",
                            }}
                            id="percentage"
                          >
                            {value[2]}
                          </span>
                        </td>
                        {!forOtherUser && (
                          <td
                            style={{
                              border: "1px solid #87CEEB",
                              wordWrap: "break-word",
                              width: "200px",
                            }}
                          >
                            {value[3][0] === "" &&
                            value[3][1] === "" &&
                            value[3][2] === "" &&
                            value[3][3] === "" &&
                            value[3][4] === "" ? (
                              <p>No pending Action</p>
                            ) : (
                              <>
                                {value[3][0] !== "" && (
                                  <span
                                    className="link-style"
                                    onClick={() => {
                                      navigate("/ApproveChanges");
                                    }}
                                  >
                                    {value[3][0]}
                                  </span>
                                )}
 
                                {value[3][1] !== "" && (
                                  <span
                                    className="link-style"
                                    onClick={() => {
                                      setapplicationName(value[0]);
                                      setRelationshipCategorySelected(
                                        relationshipCategory[1]
                                      );
                                      navigate("/main");
                                      setautosearch(true);
                                      setSelectedSuggestion(true);
                                    }}
                                  >
                                    {value[3][1]}
                                  </span>
                                )}

                                {value[3][3] !== "" && (
                                  <span
                                    className="link-style"
                                    onClick={() => {
                                      setapplicationName(value[0]);
                                      setRelationshipCategorySelected(
                                        relationshipCategory[2]
                                      );
                                      navigate("/main");
                                      setautosearch(true);
                                      setSelectedSuggestion(true);
                                    }}
                                  >
                                    {value[3][3]}
                                  </span>
                                )}

                                {value[3][2] !== "" && (
                                  <span
                                    className="link-style"
                                    onClick={() => {
                                      setcertifyapplicationName(value[0]);
                                      navigate("/certify");
                                      setautosearch1(true);
                                    }}
                                  >
                                    {value[3][2]}
                                  </span>
                                )}

                                {value[3][4] !== "" && (
                                  <span
                                    className="link-style"
                                    onClick={() => {
                                      // console.log('TEST CLICK')
                                      setcertifyapplicationName(value[0]);
                                      navigate("/certify");
                                      setautosearch1(true);
                                    }}
                                  >
                                    {value[3][4]}
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div style={{ textAlign: "center" }}>
              No ownership record found.
            </div>
          )}
          <div style={{ marginTop: "10vh", visibility: "hidden" }}>""</div>
        </div>
      ) : null}
    </div>
  );
};
export default LandingPage1;